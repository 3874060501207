import { type ReactElement } from "react";
import { Outlet, useRoutes } from "react-router-dom";
import HallOfOrganisations from "./hall-of-organisations";
import HallOfFarmes from "./hall-of-farms";
import Trials from "./new-trail";
import { NewTrialStateProvider } from "./new-trail/hooks/NewTrialStateProvider";
import CreateNewTrial from "./trials";
import CreateProtocol from "./create-protocol";
import Protocols from "./protocols";
import { route } from "./constant";

export const AppRoutes = (): ReactElement | null => {
  return useRoutes([
    {
      path: "/app",
      element: <Outlet />,
      children: [
        {
          path: route.ORGS.path,
          element: <HallOfOrganisations />,
        },
        {
          path: route.FARMS.path,
          element: <HallOfFarmes />,
        },
        {
          path: route.TRIALS.path,
          element: <CreateNewTrial />,
        },
        {
          path: `${route.TRIALS.path}/:id`,
          element: (
            <NewTrialStateProvider>
              <Trials />
            </NewTrialStateProvider>
          ),
        },
        {
          path: route.NEWTRIAL.path,
          element: (
            <NewTrialStateProvider>
              <Trials />
            </NewTrialStateProvider>
          ),
        },
        {
          path: route.PROTOCOLS_DASHBOARD.path,
          element: <Protocols />,
        },
        {
          path: `${route.PROTOCOLS.path}/:step`,
          element: <CreateProtocol />,
        },
      ],
    },
  ]);
};
