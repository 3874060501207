import { type ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../../../../../shared/utils/translationUtils";

const BiologicalHeading = (): ReactElement => {
  const { t } = useTranslation();
  return (
    <div>
      <h2 className="biologicals-header">
        {formatTranslation(t("createProtocol.products.biologicals.header"))}
      </h2>
      <p className="biologicals-description">
        {formatTranslation(
          t("createProtocol.products.biologicals.description.message")
        )}
      </p>
    </div>
  );
};

export default BiologicalHeading;
