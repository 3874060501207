export const route = {
  ORGS: { path: "orgs" },
  FARMS: { path: "farms" },
  TRIALS: { path: "trials" },
  NEWTRIAL: { path: "new-trial" },
  PROTOCOLS_DASHBOARD: { path: "protocols/dashboard" },
  PROTOCOLS: { path: "protocols" },
  PROTOCOLS_TRIALTYPE: { path: "trial-type" },
  PROTOCOLS_RULES: { path: "rules" },
  PROTOCOLS_BASICINFO: { path: "basic-info" },
  PROTOCOLS_OBJECTIVES: { path: "objectives" },
  PROTOCOLS_APPLICATIONS: { path: "applications" },
  PROTOCOLS_PRODUCTS: { path: "products" },
  PROTOCOLS_SUMMARY: { path: "summary" },
};

export const basepath = "/app";
