import { useEffect, useState, type ReactElement } from "react";
import BiologicalHeading from "./Heading/BiologicalHeading";
import MixRateApplicationVolume from "./MixRate/MixRateApplicationVolume";
import SelectBiologicalProduct from "./Product/SelectBiologicalProduct";
import { useCreateProtocolForm } from "../../..";
import AxiosInstance from "../../../../../shared/utils/axios";
import { useSearchParams } from "react-router-dom";
import ApplicationComponent from "./Applications/ApplicationComponent";

const ProductsBiologicalComponents = (): ReactElement => {
  const { form, formValidationData, setFormValidationData } =
    useCreateProtocolForm();
  const [existingData, setExistingData] = useState<any>(null);
  const [searchParams] = useSearchParams();
  const protocolId = searchParams.get("protocol_id");
  const [loading, setLoading] = useState<boolean>(true);
  const [isUpadated, setIsUpdated] = useState<boolean>(false);

  const getProtocolData = async (): Promise<any> => {
    try {
      const response = await AxiosInstance.get(
        `/protocol/${protocolId as string}`
      );
      setExistingData(response?.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    getProtocolData().catch((error) => {
      console.error("Error fetching data:", error);
    });
  }, []);
  useEffect(() => {
    if (!loading) {
      if (existingData?.product_details?.biologicals) {
        form.setFieldsValue({
          biologicals: { ...existingData?.product_details?.biologicals },
        });
      } else {
        form.setFieldsValue({
          biologicals: {
            tank_mix_rate: {
              min: 0,
              max: 0,
            },
            products: [],
            applications: [],
          },
        });
      }
      setIsUpdated(true);
    }
  }, [existingData]);
  return (
    <div className="products-biologicals-component">
      <BiologicalHeading />
      <SelectBiologicalProduct
        loading={!isUpadated}
        country={existingData?.crop_details?.country || null}
        orgId={existingData?.org_id}
      />
      <MixRateApplicationVolume loading={!isUpadated} />
      <ApplicationComponent loading={!isUpadated} />
    </div>
  );
};

export default ProductsBiologicalComponents;
