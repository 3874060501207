import type mapboxgl from "mapbox-gl";
import * as turf from "@turf/turf";
import type {
  TurfFeatureCollectionType,
  TurfLineStringType,
  TurfPolygonType,
} from "../components/MapComponent/type";

interface Options {
  spacing?: number;
  color?: string;
}

export function drawMachineryGridPure(
  map: mapboxgl.Map,
  turfPolygon: TurfPolygonType,
  start: number[],
  end: number[],
  gridSourceId: string,
  lineId: string,
  options: Options = { spacing: 100, color: "#888" }
): void {
  const { spacing = 100, color = "#888" } = options;
  const baseLine = extendLine(start, end, 1000);
  const gridLines = createGridLines(baseLine, turfPolygon, spacing);

  const gridData: TurfFeatureCollectionType = {
    type: "FeatureCollection",
    features: gridLines,
  };

  if (map.getSource(gridSourceId)) {
    (map.getSource(gridSourceId) as mapboxgl.GeoJSONSource).setData(gridData);
  } else {
    map.addSource(gridSourceId, { type: "geojson", data: gridData });
    map.addLayer({
      id: lineId,
      type: "line",
      source: gridSourceId,
      paint: { "line-color": color, "line-width": 2 },
    });
  }
}

function extendLine(
  start: number[],
  end: number[],
  scaleFactor: number
): TurfLineStringType {
  const [dx, dy] = [end[0] - start[0], end[1] - start[1]];
  return turf.lineString([
    [start[0] - dx * scaleFactor, start[1] - dy * scaleFactor],
    [end[0] + dx * scaleFactor, end[1] + dy * scaleFactor],
  ]);
}

function createGridLines(
  baseLine: TurfLineStringType,
  polygon: TurfPolygonType,
  spacing: number
): TurfLineStringType[] {
  const gridLines: TurfLineStringType[] = [];
  const maxOffset = 500; // in kilometers
  const step = spacing / 1000; // convert spacing to kilometers

  for (const direction of [0, -1, 1]) {
    for (let offset = step; Math.abs(offset) <= maxOffset; offset += step) {
      const offsetLine = turf.lineOffset(baseLine, offset * direction, {
        units: "kilometers",
      });
      const points = turf
        .lineIntersect(offsetLine, polygon)
        .features.map((f) => f.geometry.coordinates as [number, number]);

      if (points.length >= 2) {
        points.sort(
          (a, b) =>
            turf.distance(offsetLine.geometry.coordinates[0], a, {
              units: "kilometers",
            }) -
            turf.distance(offsetLine.geometry.coordinates[0], b, {
              units: "kilometers",
            })
        );

        for (let i = 0; i < points.length - 1; i += 2) {
          gridLines.push(turf.lineString([points[i], points[i + 1]]));
        }
      }

      if (points.length < 2 || direction === 0) break;
    }
  }

  return gridLines;
}
