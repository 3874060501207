import { getCircleColor, getCircleColorNutrient } from "../../../shared/layout/Plots/utils";
import { type ProtocolResponse } from "../types";

export const addRatesDosagesToPlotProperties = (
  trialData: any,
  protocolData: ProtocolResponse | null
): any => {
  const updatedTrialData = { ...trialData };

  updatedTrialData?.trial_plots?.forEach((plot: any, index: number) => {
    const measureUnit = updatedTrialData?.rates_and_dosages?.unit_of_rate;
    const properties =
      updatedTrialData?.rates_and_dosages?.rates_and_dosages[index];
    const updatedProperties = properties?.map((property: any, plotIndex: number) => {
      return {
        plot_id: plotIndex+1,
        color: getCircleColor(property.variety, property.rate, protocolData?.product_details?.seeds?.products ?? [],
        trialData?.rates_and_dosages?.unit_of_rate,
        plot?.properties?.seeds?.rates_and_dosages),
        variety: property.variety,
        rate: property.rate,
        measure_unit: measureUnit,
      };
    });

    const updatedFertiliserProperties = properties?.map((property: any, plotIndex: number) => {
      return {
        plot_id: plotIndex+1,
        color: getCircleColorNutrient(trialData?.rates_and_dosages?.standard_fertilisers,
          property?.fertiliser_rate,
          undefined,
          plotIndex),
        rate: property.fertiliser_rate,
        amount: property.fertiliser_amount        
      };
    });

    plot.plot.plot_id = plot.plot.id;
    plot.properties = {};
    if (protocolData?.protocol_type?.includes("seed")) {
      plot.properties.seeds = {
        measure_unit: measureUnit || "seeds/m2",
        rates_and_dosages: updatedProperties,
      };
    }
    if (protocolData?.protocol_type?.includes("nutrient")) {
      plot.properties.fertilisers = {
        measure_unit: "kg/ha",
        rates_and_dosages: updatedFertiliserProperties,
      };
    }
  });
  return updatedTrialData;
};
