import { type FC } from "react";
import { type DeleteIconProps } from "../types";
import "./deleteIcon.less";
import deleteDiseableIcon from "../../../assets/images/delete-disabled.svg"; // Adjust the path as necessary
import deleteIcon from "../../../assets/images/delete.svg"; // Adjust the path as necessary

export const DeleteIcon: FC<DeleteIconProps> = ({
  onClick,
  children,
  disabled,
}) => {
  return (
    <>
      {disabled === true ? (
        <img src={deleteDiseableIcon} className="delete-button-disabled">
          {children}
        </img>
      ) : (
        <img src={deleteIcon} onClick={onClick} className="delete-button">
          {children}
        </img>
      )}
    </>
  );
};
