import { useEffect, useState, type ReactElement } from "react";
import { PTable } from "../../../../shared/layout/PTable";
import { Drawer, Flex, Select } from "antd";
import { Search } from "../../../../shared/layout/Search";
import boxIcon from "../../../../../src/assets/images/product-search-icon.svg";
import { useCreateProtocolForm } from "../..";
import { measureUnitOptions } from "../../constants";
import { useSearchParams } from "react-router-dom";
import AxiosInstance from "../../../../shared/utils/axios";
import PlatFormAPIAxiosInstance from "../../../../shared/utils/platFormAxios";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../../../shared/utils/translationUtils";
import { DensityRangeComponent } from "../../shared/components/DensityRangeComponent";
import { ProductCard } from "../../shared/components/ProductCard";
import { transformProductList } from "../../shared/utils/common-utils";
import type { ProtocolResponse } from "../../shared/interfaces/product-interfaces";

interface Product {
  key: number;
  id: string;
  variety: string;
  manufacturer: string;
  measure_unit: string;
  density_range?: {
    max?: number;
    min?: number;
  };
}

const ProductsSeedComponent = (): ReactElement => {
  const { t } = useTranslation();
  const { form, formValidationData, setFormValidationData } =
    useCreateProtocolForm();

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
  const [productList, setProductList] = useState<any>([]);
  const [globalDensityRange, setGlobalDensityRange] = useState<boolean>(false);
  const [globalDensityValues, setGlobalDensityValues] = useState<any>({
    max: 1,
    min: 1,
  });
  const [measureUnitSelection, setMeasureUnitSelection] =
    useState<string>("seeds/m2");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [loading, setloading] = useState(true);
  const [searchParams] = useSearchParams();
  const protocolId = searchParams.get("protocol_id");
  const [existingData, setExistingData] = useState<any>(null);

  const getProtocolData = async (): Promise<any> => {
    try {
      const response = await AxiosInstance.get(
        `/protocol/${protocolId as string}`
      );
      setExistingData(response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (protocolId) {
      getProtocolData().catch((error) => {
        console.error("Error fetching data:", error);
      });
    }
  }, [protocolId]);

  useEffect(() => {
    if (existingData?.data) {
      getProductList(
        existingData?.data?.crop_details?.localized_crop_ids
      ).catch((e) => {
        console.error(e);
      });
      setFormValidationData({ ...formValidationData, products: [] });
    }

    if (existingData?.data && existingData?.data?.product_details?.seeds) {
      setSelectedProducts(existingData?.data?.product_details?.seeds?.products);
      setGlobalDensityRange(
        existingData.data.product_details?.seeds?.global_density_range
      );

      setGlobalDensityValues({
        min: existingData?.data?.product_details?.seeds?.density_range?.min,
        max: existingData?.data?.product_details?.seeds?.density_range?.max,
      });
      setMeasureUnitSelection(
        existingData?.data?.product_details?.seeds?.measure_unit
      );
      setFormValidationData({
        ...formValidationData,
        measure_unit: existingData.data.product_details?.seeds?.measure_unit,
        products: existingData.data.product_details?.seeds?.products,
        density_range:
          existingData?.data?.product_details?.seeds?.density_range ??
          globalDensityValues,
      });
      // get Product ids
      // find products in full list
      // mark them as checked/unchecked
      // add checked index to setSelectedRowKeys
      // add that array to selectedProducts
      // if does not exist in the full list, ignore
      // if the list changes, update again to check if selected items are included
    }
  }, [existingData, protocolId]);

  useEffect(() => {
    form.setFieldsValue({
      seed_details: {
        density_range: {
          max: globalDensityValues?.max,
          min: globalDensityValues?.min,
        },
        global_density_range: globalDensityRange,
        measure_unit: measureUnitSelection,
        products: selectedProducts,
      },
    });
  }, [
    selectedProducts,
    globalDensityRange,
    globalDensityValues,
    measureUnitSelection,
  ]);

  const updateProductDensityRange = (
    productId: string,
    densityType: "max" | "min",
    value: number
  ): void => {
    if (globalDensityRange) {
      setGlobalDensityValues({ ...globalDensityValues, [densityType]: value });
      setFormValidationData({
        ...formValidationData,
        density_range: { ...globalDensityValues, [densityType]: value },
      });
    } else {
      const modifiedProducts = selectedProducts.map((product) => {
        if (product.id === productId) {
          return {
            ...product,
            density_range: { ...product.density_range, [densityType]: value },
          };
        } else return product;
      });
      setSelectedProducts(modifiedProducts);
      setFormValidationData({
        ...formValidationData,
        products: modifiedProducts,
        density_range: null,
      });
    }
  };

  let formattedProducts: Product[] = [];

  const getProductList = async (localizedCropIds: string[]): Promise<void> => {
    const payload = {
      localized_crop_id: localizedCropIds,
      show_cononical: true,
    };

    const response: ProtocolResponse = await PlatFormAPIAxiosInstance.post(
      "/catalog/seeds/query?page=1&size=100",
      payload
    );
    setProductList(response?.data);
    setloading(false);
  };

  if (productList?.content && productList?.content.length > 0) {
    formattedProducts = transformProductList(productList?.content);
  } else {
    formattedProducts = [];
  }

  const onSelectChange = (newSelectedRowKeys: React.Key[]): void => {
    setSelectedRowKeys(newSelectedRowKeys);
    const selected = formattedProducts.filter((product: Product) =>
      newSelectedRowKeys.find((x) => x === product.key)
    );

    setSelectedProducts(
      selected?.map((item) => ({
        ...item,
        measure_unit: measureUnitSelection,
      }))
    );

    setFormValidationData({
      ...formValidationData,
      products: selected?.map((item) => ({
        ...item,
        density_range: null,
      })),
      measure_unit: measureUnitSelection,
      density_range: globalDensityValues,
    });
  };

  const onDeleteProduct = (key: number): void => {
    const indexTodDelete = selectedRowKeys.findIndex((x) => x === key);
    const updatedRowKeys = selectedRowKeys.filter(
      (x, index) => index !== indexTodDelete
    );
    setSelectedRowKeys(updatedRowKeys);
    const selected = formattedProducts.filter((product: Product) =>
      updatedRowKeys.find((x) => x === product.key)
    );
    setSelectedProducts(selected);
    setFormValidationData({
      ...formValidationData,
      products: selected,
      density_range: null,
    });
  };

  const columns = [
    {
      title: formatTranslation(t("createProtocol.products.tableTitle1")),
      dataIndex: "variety",
      key: "variety",
      sorter: (a: any, b: any) => a.variety.localeCompare(b.variety),
    },
    {
      title: formatTranslation(t("createProtocol.products.tableTitle2")),
      dataIndex: "manufacturer",
      key: "manufacturer",
      sorter: (a: any, b: any) => a.manufacturer.localeCompare(b.manufacturer),
    },
  ];
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedProducts?.length > 0;

  const handleTableChange = (): void => {};

  const closeDrawer = (): void => {
    setIsDrawerOpen(false);
  };

  const handleMeasureUnitChange = (value: string): void => {
    setFormValidationData({
      ...formValidationData,
      measure_unit: value,
    });
    setMeasureUnitSelection(value);
    setSelectedProducts(
      selectedProducts?.map((item) => ({
        ...item,
        measure_unit: value,
      }))
    );
  };

  const toggleDensityRange = (e: boolean): void => {
    setGlobalDensityRange(e);
    if (!e) setGlobalDensityValues({ max: 1, min: 1 });
  };

  const [searchText, setSearchText] = useState<string>("");
  const [filteredProductsData, setFilteredProductsData] = useState<Product[]>();

  const searchData = (): void => {
    const filteredData = formattedProducts?.filter((entry: any) => {
      return entry.variety
        .toLocaleLowerCase()
        .includes(searchText.toLocaleLowerCase());
    });
    setFilteredProductsData(filteredData);
  };

  useEffect(() => {
    searchData();
  }, [searchText, formattedProducts?.length]);

  return (
    <div className="products-seed-component">
      <div className="products-left">
        <h3>{formatTranslation(t("createProtocol.products.title"))}</h3>
        <div className="product-left-content">
          <Search
            className="text-semibold products-search"
            searchText={searchText}
            setSearchTexts={setSearchText}
          />
          <div>
            <p>
              {hasSelected ? `Selected ${selectedRowKeys?.length} items` : ""}
            </p>

            <PTable
              rowSelection={rowSelection}
              onChange={handleTableChange}
              columns={columns}
              dataSource={filteredProductsData ?? formattedProducts}
              loading={loading}
            />
          </div>
        </div>
      </div>

      <div
        className={`products-right ${
          !hasSelected ? "empty-product-state" : ""
        }`}
      >
        {!hasSelected ? (
          <div className="drawer-newitem">
            <img src={boxIcon} alt="product empty state icon" />
            <div className="empty-state-pcontent">
              <h3>
                {formatTranslation(
                  t("createProtocol.products.noProductSelected")
                )}
              </h3>
              <p>{formatTranslation(t("createProtocol.products.paragraph"))}</p>
            </div>
          </div>
        ) : (
          <>
            <div className="prodcuts-right-header">
              <h3>
                {formatTranslation(t("createProtocol.products.rightTilte"))}
              </h3>
            </div>

            <div className="drawer-options">
              <div className="drawer-density-measure-unit global-density-range">
                <p>
                  {formatTranslation(
                    t("createProtocol.products.densityRangeApply")
                  )}
                </p>
                <div className="density-switch">
                  <button
                    className={`density-switch-all ${
                      globalDensityRange ? "isActive" : ""
                    }`}
                    onClick={() => {
                      toggleDensityRange(true);
                    }}
                  >
                    To all
                  </button>

                  <button
                    className={
                      "density-switch-individual" +
                      (!globalDensityRange ? " isActive" : "")
                    }
                    onClick={() => {
                      toggleDensityRange(false);
                    }}
                  >
                    Individually
                  </button>
                </div>
              </div>
              <div className="drawer-density-measure-unit">
                <p>
                  {formatTranslation(t("createProtocol.products.measureUnits"))}
                </p>
                <Select
                  className="measurement-unit-select"
                  size="small"
                  defaultValue={measureUnitSelection}
                  value={measureUnitSelection}
                  onChange={(value: string): void => {
                    handleMeasureUnitChange(value);
                  }}
                  options={measureUnitOptions}
                />
              </div>
            </div>
            {globalDensityRange ? (
              <DensityRangeComponent
                globalDensityRange={globalDensityRange}
                globalDensityValues={globalDensityValues}
                updateProductDensityRange={updateProductDensityRange}
              />
            ) : (
              <></>
            )}
            <div className="product-card-container">
              <p className="product-cart-heading" style={{ width: "36%" }}>
                Products
              </p>
              <p className="product-cart-heading" style={{ width: "64%" }}>
                Density Range in {measureUnitSelection}
              </p>
              {selectedProducts.map((product) => {
                return (
                  <Flex vertical key={product.id}>
                    <ProductCard
                      id={product.id}
                      key={product.key}
                      updateProductDensityRange={updateProductDensityRange}
                      onDeleteProduct={onDeleteProduct}
                      productKey={product.key}
                      globalDensityRange={globalDensityRange}
                      globalDensityValues={globalDensityValues}
                      variety={product.variety}
                      manufacturer={product.manufacturer}
                      measure_unit={product.measure_unit}
                      productDensityRangeMin={product.density_range?.min}
                      productDensityRangeMax={product.density_range?.max}
                    />
                  </Flex>
                );
              })}
            </div>
          </>
        )}
        <Drawer
          className="objective-drawer"
          rootClassName="objective-drawer"
          title="Objective information"
          onClose={closeDrawer}
          open={isDrawerOpen}
        >
          <h2 className="objective-drawer-name">Product Details</h2>
          <div>
            <h3>SY KingsBarn</h3>
            <br />
            <div>Variety Overview</div>
            <div>
              <strong>End-use group: </strong> Six-row feed, Hybrid
            </div>
            <div>
              <strong>Market options: </strong> Livestock Feed
            </div>
          </div>
        </Drawer>
      </div>
    </div>
  );
};

export default ProductsSeedComponent;
