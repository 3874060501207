import { colorMatrix } from "../../../maps/components/MapComponent/constants";
import type { RatesDosageDataInterface } from "../../../pages/new-trail/types";

function calculateAverage(
  data: RatesDosageDataInterface[],
  key: keyof RatesDosageDataInterface
): number {
  const total = data.reduce((sum, item) => sum + (Number(item[key]) ?? 0), 0);
  return total / data.length;
}

function getColorBasedOnRate(
  rate: number,
  average: number,
  rates: number[],
  colorShade: any,
  idx: number
): string {
  if(!Array.isArray(colorShade)) {
    return "";
  }
  
  if (rate < average) {
    const lowerThanAvg = rates.filter((r) => r < average).sort((a, b) => b - a);
    const position = lowerThanAvg.indexOf(rate);
    if (position === 0) return colorShade[40] ?? "";
    if (position === 1) return colorShade[30] ?? "";
    return colorShade[20] ?? "";
  }
  if (rate > average) {
    const higherThanAvg = rates
      .filter((r) => r > average)
      .sort((a, b) => a - b);
    const position = higherThanAvg.indexOf(rate);
    if (position === 0) return colorShade[60] ?? "";
    if (position === 1) return colorShade[70] ?? "";
    if (position === 2) return colorShade[80] ?? "";
    return colorShade[90] ?? "";
  }
  return colorShade[50] ?? "";
}

export const getCircleColor = (
  variety: string | undefined,
  rate: number | undefined,
  trialProducts: any,
  trialMeasureUnit?: any,
  ratesDosageList?: any
): string => {
  if (!trialProducts || !variety || !rate) return "";

  const idx = trialProducts.map((x: any) => x.variety).indexOf(variety) % 10;
  const variantRates =
    ratesDosageList?.filter((item: any) => item.variety === variety) ?? [];

  if (variantRates.length === 0) return "";
  if (variantRates.length === 1) return colorMatrix[idx]?.[50] ?? "";

  const variantAverage = calculateAverage(variantRates, "rate");
  const rates = variantRates.map((item: any) => item.rate);
  return getColorBasedOnRate(
    rate,
    variantAverage,
    rates,
    colorMatrix[idx],
    idx
  );
};

export function getCircleColorNutrient(
  data: RatesDosageDataInterface[],
  rate: number,
  key?: keyof RatesDosageDataInterface,
  plotIndex?: number
): string {
  if (!rate) {
    return "";
  }
  let valueKey: keyof RatesDosageDataInterface = "fertiliser_rate";
  if (key) {
    valueKey = key;
  }
  const average = calculateAverage(data, valueKey);
  const rates = data.map((item) => (item[valueKey] as number) ?? 0);
  const blueColorShade = colorMatrix[plotIndex ?? 0] ?? {}; // Assuming you meant the first element
  return getColorBasedOnRate(rate, average, rates, blueColorShade, 0);
}
