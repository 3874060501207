import { Flex, Select } from "antd";
import { useEffect, type FC } from "react";
import PlotsComponent from "../../../../shared/layout/Plots";
import { useNewTrialState } from "../../hooks/NewTrialStateProvider";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../../../shared/utils/translationUtils";
import StandardSeedRate from "../../../../shared/layout/StandardSeedRate";

export const RateUnitDropDown = (): React.ReactElement => {
  const { t } = useTranslation();
  const { setRatesDosage, ratesDosage } = useNewTrialState();
  const { trialInfo } = useNewTrialState();

  const measureUnitFromTrialInfo =
    trialInfo?.trial_plots?.[0]?.properties?.seeds?.measure_unit ?? null;

  const unit = measureUnitFromTrialInfo ?? ratesDosage.unit_of_rate;

  const handleSelection = (value: string): void => {
    const updatedRatesDosage = {
      ...ratesDosage,
      unit_of_rate: value,
    };
    setRatesDosage(updatedRatesDosage);
  };
  useEffect(() => {
    handleSelection(unit);
  }, [unit]);

  return (
    <div className="rating-dropdown-container">
      <div className="rates-select">
        <h3 className="rates-title">
          {formatTranslation(t("newTrial.ratesDosage.rates"))}
        </h3>
        <Select
          value={ratesDosage?.unit_of_rate}
          onChange={(value) => {
            handleSelection(value);
          }}
        >
          <Select.Option value="seeds/ha">
            {formatTranslation(t("newTrial.ratesDosage.unit1"))}
          </Select.Option>
          <Select.Option value="seeds/m2">
            {formatTranslation(t("newTrial.ratesDosage.unit2"))}
          </Select.Option>
        </Select>
      </div>
    </div>
  );
};

const SeedsComponent: FC = () => {
  return (
    <div className="rates-dosage">
      <Flex justify="end">
        <RateUnitDropDown />
      </Flex>
      <StandardSeedRate />
      <PlotsComponent from={"seeds"} />
    </div>
  );
};

export default SeedsComponent;
