import { Collapse } from "antd";
import { type FC, useEffect } from "react";
import { useNewTrialState } from "../../../pages/new-trail/hooks/NewTrialStateProvider";
import populateRatesDosage from "../../../pages/new-trail/hooks/PopulateRatesDosage";

import {
  type RatesDosageDataInterface,
  type PlotsTableComponentProps,
  type Replicants,
} from "../../../pages/new-trail/types";
import PlotTable from "./PlotTable";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../utils/translationUtils";

const RepicantsLabel = ({
  data = [],
}: {
  data: Replicants[];
}): React.ReactElement => <>{`+ ${data.length} replicants`}</>;

const PlotsComponent: FC<PlotsTableComponentProps> = ({ from }) => {
  const {
    ratesDosage,
    newTrialState,
    setRatesDosage,
    activeKeys,
    trialProtocolData,
  } = useNewTrialState();
  const { t } = useTranslation();

  const { trialInfo } = useNewTrialState();
  const measureUnitFromTrialInfo =
    trialInfo?.trial_plots?.[0]?.properties?.seeds?.measure_unit ?? null;
  const measureUnitFromProtocol =
    trialProtocolData?.product_details?.seeds?.measure_unit;

  const unit =
    ratesDosage.unit_of_rate ??
    measureUnitFromTrialInfo ??
    measureUnitFromProtocol;

  useEffect(() => {
    const ratesDosageValue = populateRatesDosage(
      newTrialState.trial_plots,
      activeKeys,
      ratesDosage?.rates_and_dosages || [],
      unit,
      ratesDosage.standard_seed_rate,
      ratesDosage.standard_product,
      ratesDosage.standard_fertilisers
    );
    setRatesDosage(ratesDosageValue);
  }, [newTrialState.trial_plots, activeKeys]);

  const trialPlots = newTrialState.trial_plots;
  const ratesAndDosesData = ratesDosage?.rates_and_dosages;

  const vaildateField = (data: RatesDosageDataInterface): any => {
    const errors = { variety: "", range: "" };

    if (!data || ((!data.variety || data.variety === "") && data.rate !== 0)) {
      errors.variety = formatTranslation(
        t("trials.plotDesign.trialPlots.errorText.variety")
      );
    } else {
      errors.variety = "";
    }

    // product from dropdown
    const selectedProduct =
      trialProtocolData?.product_details?.seeds?.products?.find(
        (product: any) => product.variety === data.variety
      );

    if (!selectedProduct) return errors;

    let range = trialProtocolData?.product_details?.seeds?.global_density_range
      ? trialProtocolData?.product_details?.seeds?.density_range
      : selectedProduct.density_range || {};

    if (
      trialProtocolData?.product_details?.seeds?.measure_unit !==
      ratesDosage.unit_of_rate
    ) {
      if (ratesDosage.unit_of_rate === "seeds/m2") {
        range = {
          min: range.min / 10000,
          max: range.max / 10000,
        };
      } else if (ratesDosage.unit_of_rate === "seeds/ha") {
        range = {
          min: range.min * 10000,
          max: range.max * 10000,
        };
      }
    }

    if (!data.variety || data.variety === "") {
      errors.variety = formatTranslation(
        t("trials.plotDesign.trialPlots.errorText.variety")
      );
    } else {
      errors.variety = "";
    }
    if (!data.rate || data.rate === 0) {
      errors.range = formatTranslation(
        t("trials.plotDesign.trialPlots.errorText.rate")
      );
    } else if (
      !isNaN(data.rate) &&
      data.rate >= range?.min &&
      data.rate <= range?.max
    ) {
      errors.range = "";
    } else {
      errors.range = `Range for selected product is ${
        String(range?.min) ?? "n/a"
      } to ${String(range?.max) ?? "n/a"}`;
    }
    return errors;
  };

  const flattenedRates = ratesDosage.rates_and_dosages
    .flatMap((item) => item)
    .map((item) => {
      return {
        ...item,
        errors: vaildateField(item) || { variety: "", range: "" },
      };
    });

  return (
    <div className="plots-collapse-main">
      {trialPlots?.map((plotsItem: any, index: number) => (
        <div className="collpase-one" key={plotsItem.plot.id}>
          <Collapse
            defaultActiveKey={plotsItem.plot.id}
            className="ant-collapse-item"
            expandIconPosition="start"
          >
            <Collapse.Panel
              key={plotsItem.plot.id}
              header={
                <div className="plots-col">
                  <h4>Plot {index + 1}</h4>
                  {plotsItem?.replicants?.length ? (
                    <RepicantsLabel data={plotsItem.replicants || []} />
                  ) : (
                    <></>
                  )}
                </div>
              }
            >
              <PlotTable
                ratesAndDoses={ratesAndDosesData[index]}
                productDetails={trialProtocolData?.product_details?.[from]}
                flattenedRates={flattenedRates}
                vaildateField={vaildateField}
                from={from}
                plotKey={index}
              />
            </Collapse.Panel>
          </Collapse>
        </div>
      ))}
    </div>
  );
};

export default PlotsComponent;
