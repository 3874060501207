export const transformProductList = (productList: any[]): any => {
  if (!productList) {
    return [];
  }
  return productList?.map((product: any, index: number) => {
    return {
      key: index + 1,
      variety: product?.commercial_name || "",
      manufacturer: product?.manufacturer || "",
      measure_unit: "",
      id: product?.id,
    };
  });
};
