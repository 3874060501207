/* eslint-disable */
/* gridRotations no long in use for plots and replicants */

import mapboxgl from "mapbox-gl";
import * as turf from "@turf/turf";
import bLineIcon from "../../../assets/images/b-line-img.svg";
import aLineIcon from "../../../assets/images/a-line-img.svg";
import moveIcon from "../../../assets/images/moveIcon.svg";
import rotateImage from "../../../assets/images/rotation.svg";
import {
  type TrialInfoInterface,
  type FieldDataInterface,
  type PolyGonInterface,
  type GridPosition,
  type PolygonGeoJSON,
  type GridFeature,
  type SetTrialInfoFunction,
} from "./type";
import {
  type NewTrialStateInterface,
  type AbLine,
  type BufferZone,
  type TrialPlot,
  type Replicants,
  type RatesDosageDataInterface,
  type PlotsValidationData,
} from "../../../pages/new-trail/types";
import { useNewTrialState } from "../../../pages/new-trail/hooks/NewTrialStateProvider";
import {
  getCircleColor,
  getCircleColorNutrient,
} from "../../../shared/layout/Plots/utils";
import _, { filter } from "lodash";
import { usePlotCardState } from "../../../shared/layout/PlotCard/utils";
import { addABLinesToMap } from "../../utils/line";
import { addBufferZoneLayer } from "../../utils/bufferZone";
import { drawMachineryGridPure } from "../../utils/abLine";

const FEATURES_INDEX_ZERO = 0;
const randomPointsCache: Record<
  number,
  turf.FeatureCollection<turf.Point>
> = {};
let previousTrialPlots: TrialPlot[] | null = null;
const RGBA_TRIAL_PLOT = "rgba(54, 57, 72, 1)";
const RGBA_TRIAL_PLOT_RED = "rgba(255,0,0,1)";
const markersForTrialPlot: Record<string, mapboxgl.Marker> = {};
const INDEX_ZERO = 0;
const INDEX_ONE = 1;
const RGBA_DASH_LINE = "rgba(49, 180, 242, 1)";
const WHITE_COLOR = "white";
type GeoJSON = turf.FeatureCollection<turf.Geometry>;
const gridPositions: Record<number, GridPosition> = {};
const gridRotations: Record<number, number> = {};
const RGBA_TRANSPARENT_COLOR = "rgba(0,0,0,0)";

const updateABWidth = (
  abItem: AbLine,
  sourceId: string,
  map: mapboxgl.Map,
  bboxOld: any,
  polygonGeoJSON: PolygonGeoJSON
) => {
  if (abItem.grid_width === 0) return;
  const turfPolygon = turf.polygon(
    polygonGeoJSON.features[0].geometry.coordinates
  );

  const layerId = `grid-line-layer-${abItem.id ?? ""}`;
  const sourceIdGrid = `grid-layer-${abItem.id ?? ""}`;
  drawMachineryGridPure(
    map,
    turfPolygon,
    abItem.geojson.geometry.coordinates[0],
    abItem.geojson.geometry.coordinates[1],
    sourceIdGrid,
    layerId,
    { spacing: abItem.grid_width }
  );
};

const checkPlotBoundaries = (
  geoJsonData: any,
  plotsValidationData?: PlotsValidationData,
  setPlotsValidationData?: any,
  newTrialState?: any
) => {
  if (!geoJsonData.features) return;
  const fieldGeometry = geoJsonData.features[0].geometry;
  const fieldPolygonFeature = turf.polygon(fieldGeometry.coordinates);

  const checkPolygon = (plotData: any, plotId: any) => {
    const plotCoordinates = plotData.geojson.geometry.coordinates;

    const allPolygonsWithin = plotCoordinates.every(
      (polygonCoords: turf.Position[][]) => {
        const plotPolygon = turf.polygon(polygonCoords);
        return turf.booleanWithin(plotPolygon, fieldPolygonFeature);
      }
    );

    const exists =
      plotsValidationData?.polygonsNotInsideField?.includes(plotId);

    if (allPolygonsWithin) {
      if (exists) {
        setPlotsValidationData((prevState: any) => ({
          ...prevState,
          polygonsNotInsideField: prevState.polygonsNotInsideField?.filter(
            (item: any) => item !== plotId
          ),
        }));
      }
    } else {
      if (!exists) {
        setPlotsValidationData((prevState: any) => ({
          ...prevState,
          polygonsNotInsideField: [
            ...(prevState.polygonsNotInsideField || []),
            plotId,
          ],
        }));
      }
    }
  };

  newTrialState?.trial_plots?.forEach((trialItem: TrialPlot) => {
    // Check main plot
    checkPolygon(trialItem.plot, trialItem.plot.id);

    // Check replicants
    trialItem.replicants?.forEach((replicant) => {
      checkPolygon(replicant, replicant.id);
    });
  });

  const checkPlotIntersections = (
    newTrialState: { trial_plots: TrialPlot[] },
    setPlotsValidationData: React.Dispatch<
      React.SetStateAction<PlotsValidationData>
    >
  ) => {
    const newIntersectingPairs: [number, number][] = [];
    const allPlots: any = [];

    // Function to create a Turf.js multiPolygon from a plot
    const createMultiPolygon = (plot: any) =>
      turf.multiPolygon(plot.geojson.geometry.coordinates);

    // Flatten all plots and replicants into a single array
    newTrialState.trial_plots.forEach((trialPlot) => {
      allPlots.push(trialPlot.plot);
      if (trialPlot.replicants) {
        allPlots.push(...trialPlot.replicants);
      }
    });

    // Create multiPolygons for all plots
    const multiPolygons = allPlots.map(createMultiPolygon);

    // Check intersections
    for (let i = 0; i < allPlots.length; i++) {
      for (let j = i + 1; j < allPlots.length; j++) {
        if (turf.booleanOverlap(multiPolygons[i], multiPolygons[j])) {
          newIntersectingPairs.push([allPlots[i].id, allPlots[j].id]);
        } else {
          newIntersectingPairs.filter(
            (item: any) =>
              item[0] === allPlots[i].id && item[1] === allPlots[j].id
          );
        }
      }
    }

    // Update the state with the new intersecting pairs
    setPlotsValidationData((prevState: PlotsValidationData) => ({
      ...prevState,
      intersectingPairs: newIntersectingPairs,
    }));
  };

  checkPlotIntersections(newTrialState, setPlotsValidationData);
};

export const useMapComponent = (): {
  createCustomMarkerElement: (iconUrl: string) => HTMLDivElement;
  updateMapData: (
    map: mapboxgl.Map | null,
    current: number,
    isFertilizerTabActive: boolean,
    fieldData: FieldDataInterface[],
    newTrialState: NewTrialStateInterface,
    setNewTrialState: any,
    createCustomMarkerElement: (iconUrl: string) => HTMLDivElement,
    geoJsonData: PolygonGeoJSON,
    plotsValidationData: PlotsValidationData,
    setPlotsValidationData: any
  ) => void;
  updateABWidth: (
    abItem: AbLine,
    sourceId: string,
    map: mapboxgl.Map,
    bbox: any,
    polygonGeoJSON: PolygonGeoJSON
  ) => void;
  checkPlotBoundaries: (
    newTrialState: any,
    geoJsonData: any,
    plotsValidationData: PlotsValidationData,
    setPlotsValidationData: any
  ) => void;
} => {
  const createCustomMarkerElement = (
    iconUrl: string,
    abItemid?: number
  ): HTMLDivElement => {
    const markerElement = document.createElement("div");
    markerElement.className = "custom-marker";
    markerElement.style.backgroundImage = `url(${iconUrl})`;
    markerElement.style.width = "30px";
    markerElement.style.height = "30px";
    markerElement.style.backgroundSize = "cover";
    return markerElement;
  };

  const { ratesDosage, trialProtocolData } = useNewTrialState();
  const { getAbLineColor } = usePlotCardState();
  const trialProducts =
    trialProtocolData?.product_details?.seeds?.products ?? [];

  const updateMapData = (
    map: mapboxgl.Map | null,
    current: number,
    isFertilizerTabActive: boolean,
    fieldData: FieldDataInterface[],
    newTrialState: NewTrialStateInterface,
    setNewTrialState: any,
    createCustomMarkerElement: (iconUrl: string) => HTMLDivElement,
    geoJsonData: PolygonGeoJSON,
    plotsValidationData: PlotsValidationData,
    setPlotsValidationData: any
  ): void => {
    if (current === 1 || current === 2 || current === 3) {
      if (newTrialState.buffer_zone) {
        addBufferZoneLayer(map, fieldData, newTrialState, geoJsonData);
      }

      if (newTrialState.ab_line) {
        addABLinesToMap(
          map,
          newTrialState,
          setNewTrialState,
          geoJsonData,
          createCustomMarkerElement,
          getAbLineColor,
          current
        );
      }
      // create function ab_state_cahnge =>
      if (newTrialState.trial_plots) {
        addTrialPlotToMap(
          map,
          newTrialState,
          geoJsonData,
          createCustomMarkerElement,
          plotsValidationData,
          setPlotsValidationData,
          checkPlotBoundaries
        );
      }
      newTrialState?.trial_plots?.forEach(
        (trialItem: TrialPlot, index: number) => {
          const trialPlotId = trialItem?.plot?.id;
          const replicantIds = trialItem?.replicants?.map((rep) => rep.id);
          const rotationMarkerId = `rotation-marker-${trialPlotId as number}`;
          const rotationMarkerIdsReplicants = replicantIds?.map(
            (replicantId) =>
              `rotation-marker-replicant-${replicantId as number}`
          );
          const envelopeCenterMarkerId = `envelope-center-marker-${
            trialPlotId as number
          }`;
          const envelopeCenterMarkersIdReplicants = replicantIds?.map(
            (replicantId) =>
              `envelope-center-marker-replicant-${replicantId as number}`
          );

          if (
            (current === 2 || current === 3) &&
            envelopeCenterMarkerId &&
            rotationMarkerId &&
            envelopeCenterMarkersIdReplicants
          ) {
            markersForTrialPlot[envelopeCenterMarkerId].remove();
            markersForTrialPlot[rotationMarkerId].remove();
            envelopeCenterMarkersIdReplicants.forEach(
              (envelopeCenterMarkerIdReplicant) => {
                markersForTrialPlot[envelopeCenterMarkerIdReplicant].remove();
              }
            );

            rotationMarkerIdsReplicants.forEach((rotationMarkerIdReplicant) => {
              markersForTrialPlot[rotationMarkerIdReplicant].remove();
            });
          }
        }
      );
    }

    if (current === 2 || current === 3) {
      const ratesDosagesArray = ratesDosage?.rates_and_dosages;
      addRatesAndDosageToMap(
        ratesDosagesArray,
        map,
        trialProducts,
        ratesDosage.unit_of_rate,
        isFertilizerTabActive
      );
    }
  };

  return {
    createCustomMarkerElement,
    updateMapData,
    updateABWidth,
    checkPlotBoundaries,
  };
};

export const initializeMap = (
  map: mapboxgl.Map | null,
  fieldData: FieldDataInterface[],
  trialInfo: TrialInfoInterface,
  current: number,
  handleFieldSelection: any
): any => {
  if (!map || !fieldData || fieldData.length === 0) {
    return undefined;
  }

  const featuresWithNames = fieldData.map((item: FieldDataInterface) => {
    const isSelected = trialInfo?.field_ids?.includes(item.id);
    return {
      ...item,
      properties: {
        name: `${item.name}`,
        fieldId: item.id,
        selected: isSelected,
      },
      type: "Feature",
    };
  });

  const selectedPolygon = featuresWithNames.find(
    (item: FieldDataInterface) => item.properties?.selected
  );

  const selectedPolygons = selectedPolygon ? [selectedPolygon] : [];

  let coordinates: any[] = [];
  fieldData.forEach((item: FieldDataInterface) => {
    if (item.hasOwnProperty("geometry"))
      coordinates.push(item.geometry.coordinates[INDEX_ZERO]);
  });

  const fieldsPolygon = turf.polygon(coordinates as any[]);

  if (current === 0) {
    const bbox = turf.bbox(fieldsPolygon);
    map.fitBounds(bbox as [number, number, number, number], { animate: false });
  } else {
    const coordinates = selectedPolygons[0].geometry;
    const bbox = turf.bbox(coordinates);
    map.fitBounds(bbox as [number, number, number, number], { animate: false });
  }

  const polygonGeoJSON: any = {
    type: "FeatureCollection",
    features: current === 0 ? featuresWithNames : selectedPolygons,
  };

  const geojsonFieldDataSource = map.getSource(
    "geojson-field-data"
  ) as mapboxgl.GeoJSONSource;
  geojsonFieldDataSource?.setData(polygonGeoJSON);

  const fieldIdList = fieldData.map((item: FieldDataInterface) => item.id);

  if (current === 0) {
    map.on("click", "polygon-fill", (e: mapboxgl.MapLayerMouseEvent) => {
      const clickedFeature = e.features?.[INDEX_ZERO];
      if (!clickedFeature) {
        return;
      }
      const clickedFieldId = clickedFeature.properties?.fieldId;
      const updatedTrialInfo: TrialInfoInterface = {
        ...trialInfo,
        field_ids: fieldIdList.includes(clickedFieldId) ? [clickedFieldId] : [],
      };

      handleFieldSelection(clickedFieldId);
    });
  }

  return polygonGeoJSON;
};

const addTrialPlotToMap = (
  map: mapboxgl.Map | null,
  newTrialState: NewTrialStateInterface,
  polygonGeoJSON: PolygonGeoJSON,
  createCustomMarkerElement: (icon: string) => HTMLElement,
  plotsValidationData: PlotsValidationData,
  setPlotsValidationData: any,
  checkPlotBoundaries: any
): void => {
  if (!map || !newTrialState) return;

  if (previousTrialPlots) {
    const previousIds = new Set(
      previousTrialPlots.map((trialItem) => trialItem.plot.id)
    );

    const currentIds = new Set(
      newTrialState.trial_plots.map((trialItem) => trialItem.plot.id)
    );

    previousIds.forEach((id) => {
      if (!currentIds.has(id)) {
        removeTrialPlotLayersAndSources(
          map,
          id as number,
          previousTrialPlots as any
        );
        removeMarkersForTrialPlot(id as number);
      }
    });

    previousTrialPlots.forEach((previousTrialItem: TrialPlot) => {
      const newTrialItem = newTrialState.trial_plots.find(
        (item) => item.plot.id === previousTrialItem.plot.id
      );

      if (previousTrialItem.replicants) {
        previousTrialItem.replicants.forEach((replicant, index) => {
          const stillExists = newTrialItem?.replicants?.some(
            (newReplicant) => newReplicant.id === replicant.id
          );

          if (!stillExists) {
            removeReplicantLayersAndSources(
              map,
              previousTrialItem.plot.id as number,
              replicant.id as number,
              index
            );
          }
        });
      }
    });
  }

  newTrialState.trial_plots.forEach((trialItem: TrialPlot) => {
    const rows = trialItem.plot.rows;
    const columns = trialItem.plot.columns;
    const plotWidth = trialItem.plot.plotWidth;
    const plotHeight = trialItem.plot.plotHeight;
    const columnSize = trialItem.plot.column_size as number;
    const rowSize = trialItem.plot.row_size as number;
    const plotGeoJson = trialItem?.plot?.geojson;
    const trialPlotId = trialItem.plot.id as number;
    const randomPointsSourceId = `random-points-${trialPlotId}`;
    const envelopeSourceId = `trial-plot-envelope-${trialPlotId}`;
    const gridSourceId = `grid-of-trial-plots-${trialPlotId}`;
    const gridLayerId = `grid-layer-${trialPlotId}`;
    const transParentLayerId = `transparent-layer-${trialPlotId}`;
    createNewTrialPlots(
      map,
      trialPlotId,
      polygonGeoJSON,
      randomPointsSourceId,
      envelopeSourceId,
      gridSourceId,
      gridLayerId,
      rows,
      columns,
      plotWidth as number,
      plotHeight as number,
      columnSize,
      rowSize,
      plotGeoJson,
      newTrialState,
      createCustomMarkerElement,
      trialItem.plot.visible as boolean,
      trialItem.plot.id as number,
      transParentLayerId,
      trialItem,
      previousTrialPlots,
      plotsValidationData,
      setPlotsValidationData,
      checkPlotBoundaries
    );
  });
  previousTrialPlots = [...newTrialState.trial_plots];
};

const removeTrialPlotLayersAndSources = (
  map: mapboxgl.Map,
  id: number,
  previousTrialPlots: TrialPlot[]
): void => {
  const randomPointsSourceId = `random-points-${id}`;
  const envelopeSourceId = `trial-plot-envelope-${id}`;
  const envelopeLayerId = `envelope-layer-${id}`;
  const gridSourceId = `grid-of-trial-plots-${id}`;
  const gridLayerId = `grid-layer-${id}`;
  const transParentLayerId = `transparent-layer-${id}`;

  if (map.getLayer(gridLayerId)) {
    map.removeLayer(gridLayerId);
  }
  map?.getStyle()?.layers?.forEach((layer) => {
    if (layer.id.includes(id.toString())) {
      map.removeLayer(layer.id);
    }
  });
  if (map.getSource(gridSourceId)) {
    map.removeSource(gridSourceId);
  }
  if (map.getLayer(envelopeLayerId)) {
    map.removeLayer(envelopeLayerId);
  }
  if (map.getSource(envelopeSourceId)) {
    map.removeSource(envelopeSourceId);
  }
  if (map.getSource(randomPointsSourceId)) {
    map.removeSource(randomPointsSourceId);
  }
  if (map.getSource(transParentLayerId)) {
    map.removeSource(transParentLayerId);
  }

  const markerId = `drag-marker-${id}`;
  if (markersForTrialPlot[markerId]) markersForTrialPlot[markerId].remove();

  const marker2Id = `rotate-marker-${id}`;
  if (markersForTrialPlot[marker2Id]) markersForTrialPlot[marker2Id].remove();

  const marker3Id = `rotation-marker-${id}`;
  if (markersForTrialPlot[marker3Id]) markersForTrialPlot[marker3Id].remove();
  const trialItem = previousTrialPlots.find((item) => item.plot.id === id);
  if (trialItem?.replicants) {
    trialItem.replicants.forEach((replicant, index) => {
      removeReplicantLayersAndSources(
        map,
        trialItem.plot.id as number,
        replicant.id as number,
        index
      );
    });
  }
};

const removeReplicantLayersAndSources = (
  map: mapboxgl.Map,
  trialPlotId: number,
  id: number,
  index: number
): void => {
  const cloneGridSourceId = `cloned-grid-of-trial-plots-${index}-${trialPlotId}-${id}`;
  const cloneGridLayerId = `cloned-grid-layer-${index}-${trialPlotId}-${id}`;
  const envelopeCenterMarkerId = `envelope-center-marker-replicant-${id}`;
  const rotationMarkerId = `rotation-marker-replicant-${id}`;

  if (markersForTrialPlot[envelopeCenterMarkerId]) {
    markersForTrialPlot[envelopeCenterMarkerId].remove();
  }
  if (markersForTrialPlot[rotationMarkerId]) {
    markersForTrialPlot[rotationMarkerId].remove();
  }
  map?.getStyle()?.layers?.forEach((layer) => {
    if (layer.id.includes(`cloned-${index}-${trialPlotId.toString()}`)) {
      map.removeLayer(layer.id);
    }
  });

  if (map.getLayer(cloneGridLayerId)) {
    map.removeLayer(cloneGridLayerId);
  }
  if (map.getSource(cloneGridSourceId)) {
    map.removeSource(cloneGridSourceId);
  }
};

const removeMarkersForTrialPlot = (trialPlotId: number): void => {
  const envelopeCenterMarkerId = `envelope-center-marker-${trialPlotId}`;
  const rotationMarkerId = `rotation-marker-${trialPlotId}`;

  if (markersForTrialPlot[envelopeCenterMarkerId]) {
    markersForTrialPlot[envelopeCenterMarkerId].remove();
  }

  if (markersForTrialPlot[rotationMarkerId]) {
    markersForTrialPlot[rotationMarkerId].remove();
  }
};

const markerForTrials = (
  map: mapboxgl.Map,
  position: mapboxgl.LngLatLike,
  icon: string,
  id: number,
  createCustomMarkerElement: (icon: string) => HTMLElement
): mapboxgl.Marker => {
  const markerId = `rotate-marker-${id}`;

  const marker = new mapboxgl.Marker({
    draggable: true,
    element: createCustomMarkerElement(icon),
  })
    .setLngLat(position)
    .addTo(map);

  markersForTrialPlot[markerId] = marker;
  return marker;
};

const markerForTrialPlots = (
  map: mapboxgl.Map,
  position: mapboxgl.LngLatLike,
  icon: string,
  id: number,
  createCustomMarkerElement: (icon: string) => HTMLElement
): mapboxgl.Marker => {
  const markerId = `drag-marker-${id}`;

  const marker = new mapboxgl.Marker({
    draggable: true,
    element: createCustomMarkerElement(icon),
  })
    .setLngLat(position)
    .addTo(map);

  markersForTrialPlot[markerId] = marker;
  return marker;
};

function getRotated(
  squareGrid: GeoJSON,
  rotationAngle: number,
  rows: number,
  columns: number
): any {
  const features: any = squareGrid.features;

  const firstPolygon: any = features[0].geometry.coordinates[0];

  const lastPolygon: any =
    features[features.length - 1].geometry.coordinates[0];

  const northWest = firstPolygon[1];
  const northEast = features[columns - 1].geometry.coordinates[0][1];
  const southWest =
    features[features.length - columns].geometry.coordinates[0][3];
  const southEast = lastPolygon[0];

  const corners = [northWest, northEast, southEast, southWest];

  const center = turf.center(squareGrid).geometry.coordinates;
  const rotatedCorners = corners.map((corner) =>
    turf.transformRotate(turf.point(corner), rotationAngle, { pivot: center })
  );
  return rotatedCorners[0].geometry.coordinates;
}

function getNorthWestCorner(
  squareGrid: GeoJSON,
  rows: number,
  columns: number
): [number, number] {
  const features: any = squareGrid.features;

  const firstPolygon: any = features[0].geometry.coordinates[0];

  const lastPolygon: any =
    features[features.length - 1].geometry.coordinates[0];
  const northWest = firstPolygon[1];
  const northEast = features[columns - 1].geometry.coordinates[0][1];
  const southWest =
    features[features.length - columns].geometry.coordinates[0][3];
  const southEast = lastPolygon[0];

  // return [northWest, northEast, southEast, southWest];
  return northWest;
}

const setLayerVisibilityOfTrialPlots = (
  map: mapboxgl.Map,
  gridLayerId: string,
  visible: boolean,
  id: number,
  gridGeoJSON: any,
  gridSourceId: string,
  transParentLayerId: string
): void => {
  if (visible) {
    map.setLayoutProperty(gridLayerId, "visibility", "visible");
    map.setLayoutProperty(transParentLayerId, "visibility", "visible");
    if (gridPositions[id] && gridRotations[id]) {
      map.setCenter([gridPositions[id].lng, gridPositions[id].lat]);
      (map.getSource(gridSourceId) as mapboxgl.GeoJSONSource).setData(
        turf.transformRotate(gridGeoJSON, gridRotations[id], {
          pivot: turf.center(gridGeoJSON).geometry.coordinates,
        })
      );
    }
    if (gridPositions[id]) {
      map.setCenter([gridPositions[id].lng, gridPositions[id].lat]);
    }
  } else {
    map.setLayoutProperty(gridLayerId, "visibility", "none");
    map.setLayoutProperty(transParentLayerId, "visibility", "none");
  }

  const markerId = `drag-marker-${id}`;
  const marker2Id = `rotate-marker-${id}`;
  const marker3Id = `envelope-center-marker-${id}`;

  if (markersForTrialPlot[marker3Id]) {
    markersForTrialPlot[marker3Id].getElement().style.display = visible
      ? "block"
      : "none";
  }

  if (markersForTrialPlot[markerId]) {
    markersForTrialPlot[markerId].getElement().style.display = visible
      ? "block"
      : "none";
  }
  if (markersForTrialPlot[marker2Id]) {
    markersForTrialPlot[marker2Id].getElement().style.display = visible
      ? "block"
      : "none";
  }
};

const createNewTrialPlots = (
  map: mapboxgl.Map,
  trialPlotId: number,
  polygonGeoJSON: PolygonGeoJSON,
  randomPointsSourceId: string,
  envelopeSourceId: string,
  gridSourceId: string,
  gridLayerId: string,
  rows: number,
  columns: number,
  plotWidth: number,
  plotHeight: number,
  columnSize: number,
  rowSize: number,
  plotGeoJson: any,
  newTrialState: NewTrialStateInterface,
  createCustomMarkerElement: (icon: string) => HTMLElement,
  visible: boolean,
  id: number,
  transParentLayerId: string,
  trialItem: any,
  previousTrialPlots: any,
  plotsValidationData: PlotsValidationData,
  setPlotsValidationData: any,
  checkPlotBoundaries: any
): void => {
  const envelopeCenterMarkerId = `envelope-center-marker-${trialPlotId}`;
  const rotationMarkerId = `rotation-marker-${trialPlotId}`;
  let rotationAngle = gridRotations[trialPlotId] || 0;

  if (
    markersForTrialPlot[envelopeCenterMarkerId] &&
    markersForTrialPlot[rotationMarkerId]
  ) {
    markersForTrialPlot[envelopeCenterMarkerId].remove();
    markersForTrialPlot[rotationMarkerId].remove();
  }

  let pointsWithinPolygon;

  const containsZeroCoordinates =
    trialItem?.plot?.geojson.geometry.coordinates.some(
      (coord: any) => coord[INDEX_ZERO] === 0 && coord[INDEX_ONE] === 0
    );

  if (containsZeroCoordinates || !randomPointsCache[trialPlotId]) {
    const randomPoints = turf.randomPoint(10, {
      bbox: turf.bbox(polygonGeoJSON),
    });
    pointsWithinPolygon = turf.pointsWithinPolygon(
      randomPoints,
      polygonGeoJSON
    );
    randomPointsCache[trialPlotId] = pointsWithinPolygon;
  } else {
    pointsWithinPolygon = randomPointsCache[trialPlotId];
  }
  const envelope = turf.envelope(pointsWithinPolygon);

  if (!map.getSource(randomPointsSourceId)) {
    map.addSource(randomPointsSourceId, {
      type: "geojson",
      data: pointsWithinPolygon as GeoJSON.FeatureCollection,
    });
  } else {
    (map.getSource(randomPointsSourceId) as mapboxgl.GeoJSONSource).setData(
      pointsWithinPolygon as GeoJSON.FeatureCollection
    );
  }

  if (!map.getSource(envelopeSourceId)) {
    map.addSource(envelopeSourceId, {
      type: "geojson",
      data: envelope,
    });
  } else {
    (map.getSource(envelopeSourceId) as mapboxgl.GeoJSONSource).setData(
      envelope
    );
  }

  const envelopeBBox = turf.bbox(envelope);

  let minX = null;
  let maxY = null;
  let minY = null;
  let maxX = null;

  let gridFeatures: Array<turf.Feature<turf.Polygon>> = [];
  let startingPoint: [number, number]; // lng, lat
  let gridGeoJSON = turf.featureCollection(gridFeatures);
  let initialRotationMarkerPosition: number[] = [];

  if (
    plotGeoJson?.geometry?.coordinates?.[0] === 0 ||
    plotGeoJson?.geometry?.coordinates?.[1] === 0 ||
    trialItem.plot.isEditing
  ) {
    [minX, minY, maxX, maxY] = envelopeBBox;
    const centerX = (minX + maxX) / 2;
    const centerY = (minY + maxY) / 2;
    const randomOffsetX = (Math.random() - 0.5) * (maxX - minX) * 0.2; // Random offset within 20% of bbox width
    const randomOffsetY = (Math.random() - 0.5) * (maxY - minY) * 0.2; // Random offset within 20% of bbox height
    startingPoint = [centerX + randomOffsetX, centerY + randomOffsetY]; // lng, lat
    let width = columnSize ?? plotWidth;
    let height = rowSize ?? plotHeight;
    const widthDeg =
      turf.convertLength(width, "meters", "degrees") /
      Math.cos((startingPoint[1] * Math.PI) / 180);
    const heightDeg = turf.convertLength(height, "meters", "degrees");

    for (let i = INDEX_ZERO; i < rows; i++) {
      for (let j = INDEX_ZERO; j < columns; j++) {
        const lng = startingPoint[0] + j * widthDeg;
        const lat = startingPoint[1] - i * heightDeg;

        const coordinates: [number, number][] = [
          [lng, lat],
          [lng, lat + heightDeg],
          [lng + widthDeg, lat + heightDeg],
          [lng + widthDeg, lat],
          [lng, lat],
        ];
        const cellPolygon = turf.polygon([coordinates]);

        const color = RGBA_TRANSPARENT_COLOR;

        cellPolygon.properties = { id: `${trialPlotId}-${i}-${j}`, color };
        gridFeatures.push(cellPolygon);
      }
    }

    /*
    If the AB line exists the plot should be rotated to match 
    the bearing of the first AB line.
    */
    if (newTrialState.ab_line.length > 0) {
      const firstAbGrid = newTrialState.ab_line[0];
      const bearing = turf.bearing(
        turf.point(firstAbGrid?.geojson?.geometry?.coordinates[0]),
        turf.point(firstAbGrid?.geojson?.geometry?.coordinates[1])
      );

      const center = turf.center(gridGeoJSON).geometry.coordinates;
      gridGeoJSON = turf.transformRotate(
        turf.featureCollection(gridFeatures),
        bearing,
        { pivot: center }
      );
    } else {
      gridGeoJSON = turf.featureCollection(gridFeatures);
    }

    initialRotationMarkerPosition = getNorthWestCorner(
      gridGeoJSON,
      rows,
      columns
    );
    trialItem.plot.geojson.geometry.coordinates = gridGeoJSON?.features.map(
      (feature) => feature?.geometry?.coordinates
    );
  } else {
    let propertiesArray: any[] = [];

    for (let i = INDEX_ZERO; i < rows; i++) {
      for (let j = INDEX_ZERO; j < columns; j++) {
        const coordinates = plotGeoJson.geometry.coordinates;

        const color = RGBA_TRANSPARENT_COLOR;
        const properties = { id: `${trialPlotId}-${i}-${j}`, color };
        propertiesArray.push(properties);
      }
    }

    plotGeoJson?.geometry?.coordinates?.forEach(
      (coordinateSet: any, index: number) => {
        const feature: any = {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: coordinateSet,
          },
          properties: propertiesArray[index] || {},
        };
        gridFeatures.push(feature);
      }
    );

    const bearing = turf.bearing(
      turf.point(plotGeoJson.geometry.coordinates[0][0][0]),
      turf.point(plotGeoJson.geometry.coordinates[0][0][1])
    );

    gridGeoJSON = turf.featureCollection(gridFeatures);

    initialRotationMarkerPosition = getNorthWestCorner(
      gridGeoJSON,
      rows,
      columns
    );

    trialItem.plot.geojson.geometry.coordinates = gridGeoJSON?.features.map(
      (feature) => feature?.geometry?.coordinates
    );
  }

  let envelopeCenter = turf.center(gridGeoJSON).geometry.coordinates;

  const updateRotationMarkerPosition = (data?: any): void => {
    initialRotationMarkerPosition = getRotated(
      gridGeoJSON,
      rotationAngle,
      rows,
      columns
    );

    rotationMarker.setLngLat(initialRotationMarkerPosition as [number, number]);
  };

  const envelopeCenterMarker = markerForTrialPlots(
    map,
    envelopeCenter as mapboxgl.LngLatLike,
    moveIcon,
    trialPlotId,
    createCustomMarkerElement
  );

  const rotationMarker = markerForTrialPlots(
    map,
    initialRotationMarkerPosition as mapboxgl.LngLatLike,
    rotateImage,
    trialPlotId,
    createCustomMarkerElement
  );

  markersForTrialPlot[rotationMarkerId] = rotationMarker;

  rotationMarker.on("drag", () => {
    const newCenter = envelopeCenterMarker.getLngLat()?.toArray() as
      | [number, number]
      | undefined;
    const newRotationMarkerPosition = rotationMarker.getLngLat()?.toArray() as
      | [number, number]
      | undefined;

    if (newCenter && newRotationMarkerPosition) {
      const newAngle = turf.bearing(
        turf.point(newCenter),
        turf.point(newRotationMarkerPosition)
      );
      rotationAngle = newAngle;

      const rotatedGrid = turf.transformRotate(gridGeoJSON, newAngle, {
        pivot: newCenter,
      }) as GeoJSON.FeatureCollection<
        turf.helpers.Polygon,
        turf.helpers.Properties
      >;

      const source = map.getSource(gridSourceId) as
        | mapboxgl.GeoJSONSource
        | undefined;
      if (source) {
        source.setData(rotatedGrid);
      }

      // grid rotations code removed
      updateRotationMarkerPosition(rotatedGrid);

      trialItem.plot.geojson.geometry.coordinates = rotatedGrid?.features.map(
        (feature) => feature?.geometry?.coordinates
      );
    }
  });
  rotationMarker.on("dragend", () => {
    // Perform actions after drag is complete, like updating coordinates
    checkPlotBoundaries(
      polygonGeoJSON,
      plotsValidationData,
      setPlotsValidationData,
      newTrialState
    );
  });

  markersForTrialPlot[envelopeCenterMarkerId] = envelopeCenterMarker;
  envelopeCenterMarker.on("drag", () => {
    const newCenter = envelopeCenterMarker.getLngLat().toArray();
    const offset = [
      newCenter[INDEX_ZERO] - envelopeCenter[INDEX_ZERO],
      newCenter[INDEX_ONE] - envelopeCenter[INDEX_ONE],
    ];

    const newGridFeatures = gridGeoJSON.features.map((feature) => {
      const coords = feature.geometry.coordinates[INDEX_ZERO].map((point) => [
        point[INDEX_ZERO] + offset[INDEX_ZERO],
        point[INDEX_ONE] + offset[INDEX_ONE],
      ]);
      return turf.polygon([coords], feature.properties);
    });

    gridGeoJSON = turf.featureCollection(newGridFeatures);

    const rotatedGrid = turf.transformRotate(gridGeoJSON, rotationAngle, {
      pivot: newCenter,
    });

    (map.getSource(gridSourceId) as mapboxgl.GeoJSONSource).setData(
      rotatedGrid
    );

    const newEnvelope = turf.transformTranslate(
      envelope,
      offset[INDEX_ZERO],
      offset[INDEX_ONE]
    );
    (map.getSource(envelopeSourceId) as mapboxgl.GeoJSONSource).setData(
      newEnvelope
    );

    initialRotationMarkerPosition = getNorthWestCorner(
      gridGeoJSON,
      rows,
      columns
    );
    rotationMarker.setLngLat(initialRotationMarkerPosition as [number, number]);
    updateRotationMarkerPosition(rotatedGrid);

    gridPositions[trialPlotId] = {
      lng: newCenter[INDEX_ZERO],
      lat: newCenter[INDEX_ONE],
    };
    envelopeCenter = newCenter;
    trialItem.plot.geojson.geometry.coordinates = rotatedGrid.features.map(
      (feature) => feature.geometry.coordinates
    );
  });
  envelopeCenterMarker.on("dragend", () => {
    // Perform actions after drag is complete, like updating coordinates
    checkPlotBoundaries(
      polygonGeoJSON,
      plotsValidationData,
      setPlotsValidationData,
      newTrialState
    );
  });

  const isError =
    plotsValidationData?.polygonsNotInsideField?.some(
      (item: any) => item === trialPlotId
    ) ||
    plotsValidationData.intersectingPairs?.some((item: any) =>
      item.some((plotId: any) => plotId === trialPlotId)
    );

  if (!map.getSource(gridSourceId)) {
    map.addSource(gridSourceId, {
      type: "geojson",
      data: gridGeoJSON,
    });

    map.addLayer({
      id: gridLayerId,
      type: "fill",
      source: gridSourceId,
      paint: {
        "fill-color": RGBA_TRANSPARENT_COLOR,
      },
    });

    map.addLayer({
      id: transParentLayerId,
      type: "line",
      source: gridSourceId,
      paint: {
        "line-color": RGBA_TRIAL_PLOT,
        "line-width": 2,
      },
    });
  } else {
    (map.getSource(gridSourceId) as mapboxgl.GeoJSONSource).setData(
      gridGeoJSON
    );
  }

  setLayerVisibilityOfTrialPlots(
    map,
    gridLayerId,
    visible,
    id,
    gridGeoJSON,
    gridSourceId,
    transParentLayerId
  );

  gridGeoJSON.features.forEach((feature, index) => {
    const cellLayerId = `${trialPlotId}-${index}`;

    if (map.getLayer(cellLayerId)) {
      map.removeLayer(cellLayerId);
    }

    map.addLayer({
      id: cellLayerId,
      type: "fill",
      source: gridSourceId,
      paint: {
        "fill-color": ["get", "color"],
        "fill-opacity": 1,
      },
      filter: ["==", ["get", "id"], feature.properties?.id],
    });

    const cellBorderLayerId = `${cellLayerId}-border`;
    if (map.getLayer(cellBorderLayerId)) {
      map.removeLayer(cellBorderLayerId);
    }

    map.addLayer({
      id: cellBorderLayerId,
      type: "line",
      source: gridSourceId,
      paint: {
        // "line-color":  RGBA_TRANSPARENT_COLOR,
        "line-color": isError ? RGBA_TRIAL_PLOT_RED : RGBA_TRANSPARENT_COLOR,
        "line-width": 2,
      },
      filter: ["==", ["get", "id"], feature.properties?.id],
    });
  });

  const trialPlot = newTrialState.trial_plots.find(
    (tp) => tp.plot.id === trialPlotId
  );

  if (trialPlot?.replicants) {
    trialPlot.replicants.forEach(
      (replicants: Replicants, cloneIndex: number) => {
        addReplicantToMap(
          replicants,
          cloneIndex,
          gridGeoJSON,
          trialPlotId,
          map,
          createCustomMarkerElement,
          replicants.id as number,
          trialItem,
          plotWidth,
          plotHeight,
          rows,
          columns,
          plotsValidationData,
          setPlotsValidationData,
          checkPlotBoundaries,
          polygonGeoJSON,
          newTrialState
        );
      }
    );
  }
};

const addReplicantToMap = (
  replicants: Replicants,
  cloneIndex: number,
  gridGeoJSON: GeoJSON,
  trialPlotId: number,
  map: mapboxgl.Map,
  createCustomMarkerElement: (icon: string) => HTMLElement,
  replicantId: number,
  trialItem: any,
  plotWidth: any,
  plotHeight: any,
  rows: number,
  columns: number,
  plotsValidationData: any,
  setPlotsValidationData: any,
  checkPlotBoundaries: any,
  polygonGeoJSON: PolygonGeoJSON,
  newTrialState: NewTrialStateInterface
): void => {
  const cloneGridFeatures: GridFeature[] = [];
  const offsetDistance = 0.25 * (cloneIndex + 1);
  const offsetAngle = 120;

  const replicantGeoJson = replicants.geojson;
  if (
    replicantGeoJson.geometry.coordinates[0] === 0 &&
    replicantGeoJson.geometry.coordinates[1] === 0
  ) {
    gridGeoJSON.features.forEach((feature: any) => {
      const clonedFeature = turf.transformTranslate(
        turf.clone(feature),
        offsetDistance,
        offsetAngle
      );
      cloneGridFeatures.push(clonedFeature);
    });
  } else {
    const clonedProperties = gridGeoJSON.features.map(
      (feature) => feature.properties
    );
    replicantGeoJson.geometry.coordinates.forEach(
      (coordinateSet: any, index: number) => {
        const feature: any = {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: coordinateSet,
          },
          properties: clonedProperties[index] || {},
        };
        cloneGridFeatures.push(feature);
      }
    );
  }
  let rotationAngle = gridRotations[replicantId] ?? 0;
  let cloneGridGeoJSON: any = turf.featureCollection(cloneGridFeatures);
  const cloneGridSourceId = `cloned-grid-of-trial-plots-${cloneIndex}-${trialPlotId}-${
    replicants.id as number
  }`;
  const cloneGridLayerId = `cloned-grid-layer-${cloneIndex}-${trialPlotId}-${
    replicants.id as number
  }`;

  const transParentLayerId = `cloned-grid-layer-${cloneIndex}-${trialPlotId}`;

  const envelopeCenterMarkerId = `envelope-center-marker-replicant-${
    replicants.id as number
  }`;
  const rotationMarkerId = `rotation-marker-replicant-${
    replicants.id as number
  }`;

  if (markersForTrialPlot[envelopeCenterMarkerId]) {
    markersForTrialPlot[envelopeCenterMarkerId].remove();
  }
  if (markersForTrialPlot[rotationMarkerId]) {
    markersForTrialPlot[rotationMarkerId].remove();
  }

  let cloneEnvelopeCenter = turf.center(cloneGridGeoJSON).geometry.coordinates;
  let rotationMarkerPosition: Array<number> = getNorthWestCorner(
    cloneGridGeoJSON,
    rows,
    columns
  );

  const updateRotationMarkerPosition = (data?: any): void => {
    rotationMarkerPosition = getRotated(
      cloneGridGeoJSON,
      rotationAngle,
      rows,
      columns
    );
    cloneRotationMarker.setLngLat(rotationMarkerPosition as [number, number]);
  };

  const cloneEnvelopeCenterMarker = markerForTrials(
    map,
    cloneEnvelopeCenter as mapboxgl.LngLatLike,
    moveIcon,
    trialPlotId,
    createCustomMarkerElement
  );

  const cloneRotationMarker = markerForTrials(
    map,
    rotationMarkerPosition as [number, number],
    rotateImage,
    trialPlotId,
    createCustomMarkerElement
  );

  markersForTrialPlot[rotationMarkerId] = cloneRotationMarker;
  cloneRotationMarker.on("drag", () => {
    const newCloneCenter = cloneEnvelopeCenterMarker.getLngLat().toArray() as [
      number,
      number
    ];
    const newRotationMarkerPosition = cloneRotationMarker
      .getLngLat()
      .toArray() as [number, number];
    const newAngle = turf.bearing(
      turf.point(newCloneCenter),
      turf.point(newRotationMarkerPosition)
    );
    rotationAngle = newAngle;

    // grid rotations code removed;

    const rotatedGrid = turf.transformRotate(cloneGridGeoJSON, newAngle, {
      pivot: newCloneCenter,
    });
    (map.getSource(cloneGridSourceId) as mapboxgl.GeoJSONSource).setData(
      rotatedGrid
    );
    updateRotationMarkerPosition(rotatedGrid);
    trialItem.replicants[cloneIndex].geojson.geometry.coordinates =
      rotatedGrid.features.map((feature: any) => feature.geometry.coordinates);
  });
  markersForTrialPlot[envelopeCenterMarkerId] = cloneEnvelopeCenterMarker;

  cloneRotationMarker.on("dragend", () => {
    // Perform actions after drag is complete, like updating coordinates
    checkPlotBoundaries(
      polygonGeoJSON,
      plotsValidationData,
      setPlotsValidationData,
      newTrialState
    );
  });
  // Example usage of handleReplicantMove
  cloneEnvelopeCenterMarker.on("drag", () => {
    const newCenter = cloneEnvelopeCenterMarker.getLngLat().toArray() as [
      number,
      number
    ];
    const offset = [
      newCenter[INDEX_ZERO] - cloneEnvelopeCenter[INDEX_ZERO],
      newCenter[INDEX_ONE] - cloneEnvelopeCenter[INDEX_ONE],
    ];

    const newGridFeatures = cloneGridGeoJSON.features.map((feature: any) => {
      const coords = feature.geometry.coordinates[INDEX_ZERO].map(
        (point: [number, number]) => [
          point[INDEX_ZERO] + offset[INDEX_ZERO],
          point[INDEX_ONE] + offset[INDEX_ONE],
        ]
      );
      return turf.polygon([coords], feature.properties);
    });

    cloneGridGeoJSON = turf.featureCollection(newGridFeatures);

    const rotatedGrid = turf.transformRotate(cloneGridGeoJSON, rotationAngle, {
      pivot: newCenter,
    });

    const source = map.getSource(cloneGridSourceId) as mapboxgl.GeoJSONSource;
    if (source) {
      source.setData(rotatedGrid);
    } else {
      map.addSource(cloneGridSourceId, {
        type: "geojson",
        data: rotatedGrid,
      });
    }

    const rotationMarkerPosition = getNorthWestCorner(
      cloneGridGeoJSON,
      rows,
      columns
    );
    cloneRotationMarker.setLngLat(rotationMarkerPosition as [number, number]);
    updateRotationMarkerPosition(rotatedGrid);

    gridPositions[replicantId] = {
      lng: newCenter[INDEX_ZERO],
      lat: newCenter[INDEX_ONE],
    };
    // grid rotations code removed

    cloneEnvelopeCenter = newCenter;

    trialItem.replicants[cloneIndex].geojson.geometry.coordinates =
      rotatedGrid.features.map((feature: any) => feature.geometry.coordinates);
  });

  cloneEnvelopeCenterMarker.on("dragend", () => {
    // Perform actions after drag is complete, like updating coordinates
    checkPlotBoundaries(
      polygonGeoJSON,
      plotsValidationData,
      setPlotsValidationData,
      newTrialState
    );
  });

  if (!map.getSource(cloneGridSourceId)) {
    map.addSource(cloneGridSourceId, {
      type: "geojson",
      data: cloneGridGeoJSON,
    });

    map.addLayer({
      id: cloneGridLayerId,
      type: "line",
      source: cloneGridSourceId,
      paint: {
        "line-color": RGBA_TRIAL_PLOT,
        "line-width": 2,
      },
    });
  } else {
    (map.getSource(cloneGridSourceId) as mapboxgl.GeoJSONSource).setData(
      cloneGridGeoJSON
    );
  }

  trialItem.replicants[cloneIndex].geojson.geometry.coordinates =
    cloneGridGeoJSON.features.map(
      (feature: any) => feature.geometry.coordinates
    );

  setLayOutPropertyOfReplicants(
    cloneGridLayerId,
    replicants.visible as boolean,
    map,
    cloneGridGeoJSON,
    cloneEnvelopeCenter as [number, number],
    cloneGridSourceId,
    cloneEnvelopeCenterMarker,
    cloneRotationMarker,
    trialPlotId
  );

  cloneGridGeoJSON.features.forEach((feature: any, index: any) => {
    const isError =
      plotsValidationData?.polygonsNotInsideField?.some(
        (item: any) => item === replicantId
      ) ||
      plotsValidationData.intersectingPairs?.some((item: any) =>
        item.some((plotId: any) => plotId === replicantId)
      );
    const cellLayerId = `cloned-${cloneIndex}-${trialPlotId}-${index}`;
    if (map.getLayer(cellLayerId)) {
      map.removeLayer(cellLayerId);
    }

    map.addLayer({
      id: cellLayerId,
      type: "fill",
      source: cloneGridSourceId,
      paint: {
        "fill-color": ["get", "color"],
        "fill-opacity": 1,
      },
      filter: ["==", ["get", "id"], feature.properties?.id],
    });

    const cellBorderLayerId = `${cellLayerId}-border`;
    if (map.getLayer(cellBorderLayerId)) {
      map.removeLayer(cellBorderLayerId);
    }

    map.addLayer({
      id: cellBorderLayerId,

      type: "line",
      source: cloneGridSourceId,
      paint: {
        "line-color": isError ? RGBA_TRIAL_PLOT_RED : RGBA_TRANSPARENT_COLOR,
        "line-width": 2,
      },
      filter: ["==", ["get", "id"], feature.properties?.id],
    });
  });
};

const setLayOutPropertyOfReplicants = (
  cloneGridLayerId: string,
  visible: boolean,
  map: mapboxgl.Map,
  cloneGridGeoJSON: GeoJSON.FeatureCollection<GeoJSON.Geometry>,
  cloneEnvelopeCenter: [number, number],
  cloneGridSourceId: string,
  cloneEnvelopeCenterMarker: mapboxgl.Marker,
  cloneRotationMarker: mapboxgl.Marker,
  trialPlotId: number
): void => {
  if (visible) {
    map.setLayoutProperty(cloneGridLayerId, "visibility", "visible");
    cloneEnvelopeCenterMarker.getElement().style.display = "block";
    cloneRotationMarker.getElement().style.display = "block";
    const replicantId = parseInt(cloneGridLayerId.split("-").pop() ?? "0");
    const storedPosition = gridPositions[replicantId];
    const storedRotation = gridRotations[replicantId];
    if (storedPosition && storedRotation !== undefined) {
      const clonedFeatureCollection = turf.featureCollection(
        cloneGridGeoJSON.features.map((feature: any) => {
          const coords = feature.geometry.coordinates[INDEX_ZERO].map(
            (point: [number, number]) => [
              point[INDEX_ZERO] +
                (storedPosition.lng - cloneEnvelopeCenter[INDEX_ZERO]),
              point[INDEX_ONE] +
                (storedPosition.lat - cloneEnvelopeCenter[INDEX_ONE]),
            ]
          );
          return turf.polygon([coords]);
        })
      );
      const rotatedGrid = turf.transformRotate(
        clonedFeatureCollection,
        storedRotation,
        {
          pivot: [storedPosition.lng, storedPosition.lat],
        }
      );
      (map.getSource(cloneGridSourceId) as mapboxgl.GeoJSONSource).setData(
        rotatedGrid
      );

      cloneEnvelopeCenterMarker.setLngLat([
        storedPosition.lng,
        storedPosition.lat,
      ]);
    }
  } else {
    map.setLayoutProperty(cloneGridLayerId, "visibility", "none");
    cloneEnvelopeCenterMarker.getElement().style.display = "none";
    cloneRotationMarker.getElement().style.display = "none";
  }
};

const addRatesAndDosageToMap = (
  ratesDosagesArray: RatesDosageDataInterface[][],
  map: mapboxgl.Map | null,
  trialProducts: any,
  unit_of_rate: string,
  isFertilizerTabActive: boolean
): void => {
  if (Array.isArray(ratesDosagesArray)) {
    ratesDosagesArray?.forEach((ratesDosageList, index: number) => {
      ratesDosageList.forEach((ratesDosageItem) => {
        const boxId = `${ratesDosageItem.plot_id as number}`;
        const borderBoxId = `${
          ratesDosageItem.plot_id as number
        }-${index}-border`;
        const plotId = borderBoxId.replace(/-\d+-border$/, "");

        map?.getStyle()?.layers?.forEach((layer: any) => {
          const filterIdParts = layer?.filter;
          if (filterIdParts && filterIdParts.length > 0) {
            const filterLastIdPart = filterIdParts[filterIdParts.length - 1];
            const isPlotLayer = plotId === filterLastIdPart;

            if (isPlotLayer) {
              // replicant id === plotid, it will have a layer with type fill

              if (layer.type === "fill") {
                const rate = ratesDosageItem.rate;
                const variety = ratesDosageItem.variety;
                const fertilizerRate = ratesDosageItem.fertiliser_rate ?? 0;

                const color = isFertilizerTabActive
                  ? getCircleColorNutrient(
                      ratesDosageList,
                      fertilizerRate,
                      undefined,
                      index
                    )
                  : getCircleColor(
                      variety,
                      rate,
                      trialProducts,
                      unit_of_rate,
                      ratesDosageList
                    );

                if (color) {
                  map?.setPaintProperty(layer.id, "fill-color", color);
                }

                map.on("mouseenter", layer.id, (e) => {
                  map.getCanvas().style.cursor = "pointer";
                  const coordinates = e.lngLat;
                  new mapboxgl.Popup({
                    closeButton: false,
                    className: "custom-popup",
                  })
                    .setLngLat(coordinates)
                    .setHTML(
                      `<div style="display: flex; flex-direction: column;">
                                    <div>Plot ${index + 1}</div>
                                    <div>${variety as string}</div>
                                    <div>${rate as number} s/ha </div>
                                </div>`
                    )
                    .addTo(map);
                });

                map.on("mouseleave", layer.id, () => {
                  map.getCanvas().style.cursor = "";
                  const popups =
                    document.getElementsByClassName("mapboxgl-popup");
                  if (popups.length > 0) popups[0].remove();
                });
              }

              if (layer.type === "line") {
                const collapseValueArray = ratesDosageItem.collapseValue || [];
                const isCollapseIdPresent =
                  collapseValueArray.includes(filterLastIdPart);

                if (plotId === filterLastIdPart) {
                  map?.setPaintProperty(
                    layer.id,
                    "line-color",
                    isCollapseIdPresent ? "gray" : "black"
                  );
                }
                map?.setPaintProperty(
                  layer.id,
                  "line-width",
                  isCollapseIdPresent ? 2 : 1 // Adjust the width values as needed
                );
              }
            }
          }
        });
      });
    });
  }
};
