import { type ReactElement } from "react";
import { Card, Col, Flex, Row, Divider } from "antd";
import useAxios from "../../../shared/custom-hooks/useAxios";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { EditButton } from "../../../shared/layout/EditButton";
import { route } from "../../constant";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../../shared/utils/translationUtils";
import { type ProtocolResponse } from "../../new-trail/types";
import { nutrientTypeOptions } from "../constants";
import BiologicalProductSummaryCard from "./Summary/BiologicalProductSummaryCard";
import { Loader } from "../../../shared/components/Loader";

const SummaryComponent = (): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const protocolId: string | null = searchParams.get("protocol_id");
  let data: ProtocolResponse | null = null;

  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const { loading, response, error } = useAxios(
    `/protocol/${protocolId ?? ""}`,
    false
  );

  data = response;

  const findNutrientLabel = (value: string): string => {
    for (const category of nutrientTypeOptions) {
      const nutrient = category.options.find(
        (option: { value: string; label: string }) => option.value === value
      );
      if (nutrient) {
        return nutrient.label;
      }
    }
    return "";
  };

  if (error) {
    return (
      <div>
        {formatTranslation(t("createProtocol.summary.errorFetchingSummary"))}
      </div>
    );
  }

  return (
    <div className="summary-component">
      {loading && (
        <div data-testid={"loader"}>
          <Loader mask message="" />
        </div>
      )}
      {data && protocolId && (
        <>
          <Card
            title={
              <div className="text-xl semibold">
                {formatTranslation(t("createProtocol.summary.title1"))}
              </div>
            }
            extra={
              <EditButton
                onClick={() => {
                  navigate({
                    pathname: `/app/${route.PROTOCOLS.path}/${route.PROTOCOLS_TRIALTYPE.path}`,
                    search: createSearchParams({
                      protocol_id: protocolId,
                    }).toString(),
                  });
                }}
              />
            }
            className="summary-card"
          >
            <p className="summary-card-static-text">
              {formatTranslation(t("createProtocol.summary.type"))}
            </p>
            <p className="summary-card-dynamic-text">
              {data.protocol_type.map(
                (type: string, index: number, arr: any[]) =>
                  index === arr.length - 1 ? type : type + ", "
              )}
            </p>
          </Card>
          <Card
            title={
              <div className="text-xl semibold">
                {formatTranslation(t("createProtocol.summary.title2"))}
              </div>
            }
            extra={
              <EditButton
                onClick={() => {
                  navigate({
                    pathname: `/app/${route.PROTOCOLS.path}/${route.PROTOCOLS_RULES.path}`,
                    search: createSearchParams({
                      protocol_id: protocolId,
                    }).toString(),
                  });
                }}
              />
            }
            className="summary-card"
          >
            <Flex gap={16} align="start" justify="space-between">
              <div>
                <p className="summary-card-static-text">
                  {formatTranslation(
                    t("createProtocol.basicInfo.protocolName")
                  )}
                </p>
                <p className="summary-card-dynamic-text">{data.name}</p>
              </div>
              <div>
                <p className="summary-card-static-text">
                  {formatTranslation(
                    t("createProtocol.basicInfo.protocolDescription")
                  )}
                </p>
                <p className="summary-card-dynamic-text">{data.description}</p>
              </div>

              <div>
                <p className="summary-card-static-text">
                  {formatTranslation(
                    t("createProtocol.basicInfo.protocolCrop")
                  )}
                </p>
                <p className="summary-card-dynamic-text">{data.crop}</p>
              </div>

              <div>
                <p className="summary-card-static-text">
                  {formatTranslation(
                    t("createProtocol.basicInfo.plotMinimumSize")
                  )}
                </p>
                <p className="summary-card-dynamic-text">
                  {data.plot_minimum_size.width} x{" "}
                  {data.plot_minimum_size.length} m
                </p>
              </div>

              <div>
                <p className="summary-card-static-text">
                  {formatTranslation(
                    t("createProtocol.basicInfo.plotRecommendedSize")
                  )}
                </p>
                <p className="summary-card-dynamic-text">
                  {data.plot_recommended_size.width} x{" "}
                  {data.plot_recommended_size.length} m
                </p>
              </div>
            </Flex>
          </Card>
          <Card
            title={
              <div className="text-xl semibold">
                {formatTranslation(t("createProtocol.summary.title3"))}
              </div>
            }
            extra={
              <EditButton
                onClick={() => {
                  navigate({
                    pathname: `/app/${route.PROTOCOLS.path}/${route.PROTOCOLS_RULES.path}`,
                    search: createSearchParams({
                      protocol_id: protocolId,
                    }).toString(),
                  });
                }}
              />
            }
            className="summary-card"
          >
            {data?.objectives?.map((objective: any, index: number) => {
              return (
                <div className="mb-8" key={index}>
                  <Row>
                    <Col span={8}>
                      <p className="summary-card-static-text">
                        {formatTranslation(
                          t("createProtocol.objectives.objectiveName")
                        )}
                      </p>
                    </Col>
                    <Col span={8}>
                      <p className="summary-card-static-text">
                        {formatTranslation(
                          t("createProtocol.objectives.objectiveItemTarget")
                        )}
                      </p>
                    </Col>
                    <Col span={8}>
                      <p className="summary-card-static-text">
                        {formatTranslation(
                          t(
                            "createProtocol.objectives.objectiveItemDescription"
                          )
                        )}
                      </p>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={8}>
                      <p className="summary-card-dynamic-text">
                        {objective?.name}
                      </p>
                    </Col>

                    <Col span={8}>
                      <p className="summary-card-dynamic-text">
                        {objective?.targets.map(
                          (target: string, index: number) =>
                            index === objective?.targets?.length - 1
                              ? target
                              : target + ", "
                        )}
                      </p>
                    </Col>
                    <Col span={8}>
                      <p className="summary-card-dynamic-text">
                        {objective.description}
                      </p>
                    </Col>
                  </Row>
                </div>
              );
            })}
          </Card>
          {data?.protocol_type.includes("seed") && (
            <Card
              title={
                <div className="text-xl semibold">
                  {formatTranslation(t("createProtocol.summary.title4"))}
                </div>
              }
              extra={
                <EditButton
                  onClick={() => {
                    navigate({
                      pathname: `/app/${route.PROTOCOLS.path}/${route.PROTOCOLS_PRODUCTS.path}`,
                      search: createSearchParams({
                        protocol_id: protocolId,
                        type: "seed",
                      }).toString(),
                    });
                  }}
                />
              }
              className="summary-card"
            >
              <Row className="products-header">
                <Col span={12}>
                  <p className="summary-card-static-text-seeds">
                    {formatTranslation(t("createProtocol.products.rightTilte"))}
                  </p>
                </Col>
                <Col span={12}>
                  <p className="summary-card-static-text-seeds">
                    {formatTranslation(
                      t("createProtocol.products.densityRange")
                    )}
                  </p>
                </Col>
              </Row>
              {data?.product_details?.seeds ? (
                <>
                  {data?.product_details?.seeds?.products.map(
                    (product: any, index: number) => {
                      return (
                        <Row key={index} className="products-row">
                          <Col span={12}>
                            <p className="summary-card-dynamic-text">
                              {product?.variety}
                            </p>
                          </Col>
                          <Col span={12}>
                            {data?.product_details?.seeds
                              ?.global_density_range ? (
                              <p className="summary-card-dynamic-text">
                                {
                                  data?.product_details?.seeds?.density_range
                                    .min
                                }{" "}
                                -{" "}
                                {
                                  data?.product_details?.seeds?.density_range
                                    .max
                                }{" "}
                                {data?.product_details?.seeds?.measure_unit}
                              </p>
                            ) : (
                              <p className="summary-card-dynamic-text">
                                {product?.density_range?.min} -{" "}
                                {product?.density_range?.max}{" "}
                                {data?.product_details?.seeds?.measure_unit}
                              </p>
                            )}
                          </Col>
                        </Row>
                      );
                    }
                  )}
                </>
              ) : (
                <>
                  {" "}
                  {formatTranslation(
                    t("createProtocol.summary.productDataNotFound")
                  )}
                </>
              )}
            </Card>
          )}
          {data?.protocol_type.includes("biological") && (
            <BiologicalProductSummaryCard
              biologicals={
                data?.product_details?.biologicals
                  ? data?.product_details?.biologicals
                  : null
              }
            />
          )}
          {data?.protocol_type.includes("nutrient") && (
            <Card
              title={
                <div className="text-xl semibold">
                  {formatTranslation(
                    t("createProtocol.products.nutrients.header")
                  )}
                </div>
              }
              extra={
                <EditButton
                  onClick={() => {
                    navigate({
                      pathname: `/app/${route.PROTOCOLS.path}/${route.PROTOCOLS_PRODUCTS.path}`,
                      search: createSearchParams({
                        protocol_id: protocolId,
                        type: "nutrient",
                      }).toString(),
                    });
                  }}
                />
              }
              className="summary-card"
            >
              {data?.product_details?.nutrients && (
                <>
                  <p className="summary-card-dynamic-text">
                    {findNutrientLabel(
                      data?.product_details?.nutrients?.products[0]
                        ?.nutrient_code
                    )}
                  </p>
                  <Flex gap={16} align="start" justify="start">
                    <div>
                      <p className="summary-card-static-text">
                        {formatTranslation(
                          t("createProtocol.summary.nutrients.applicationRange")
                        )}
                      </p>
                      <p className="summary-card-dynamic-text">
                        {
                          data?.product_details?.nutrients?.application_range
                            ?.min
                        }
                        % min -{" "}
                        {
                          data?.product_details?.nutrients?.application_range
                            ?.max
                        }
                        % max
                      </p>
                    </div>
                    <Divider type="vertical" style={{ height: "52px" }} />
                    <div>
                      <p className="summary-card-static-text">
                        {formatTranslation(
                          t("createProtocol.summary.nutrients.minDistanceUnit")
                        )}
                      </p>
                      <p className="summary-card-dynamic-text">
                        {data?.product_details?.nutrients?.interval_distance}%
                      </p>
                    </div>
                    <Divider type="vertical" style={{ height: "52px" }} />
                    <div>
                      <p className="summary-card-static-text">
                        {formatTranslation(
                          t("createProtocol.summary.nutrients.noOfIntervals")
                        )}
                      </p>
                      <p className="summary-card-dynamic-text">
                        {data?.product_details?.nutrients?.number_of_intervals}
                      </p>
                    </div>
                  </Flex>
                </>
              )}
            </Card>
          )}
        </>
      )}
    </div>
  );
};
export default SummaryComponent;
