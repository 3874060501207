import { get } from "lodash";
import { type StandardFertiliser } from "../types";

export const populateRatesDosage = (
  trialPlotData: any,
  activeKey: string[],
  existingRates: any,
  unit?: string,
  standardSeedRate?: number,
  standardProduct?: string,
  standardfertilisers?: StandardFertiliser[]
): any => {
  const ratesAndDosages: any = [];
  const existingRatesDosage = existingRates?.rates_and_dosages || [];
  // const selectedUnit = get(
  //   trialPlotData,
  //   "[0].properties.seeds.measure_unit",
  //   unit
  // );
  trialPlotData?.forEach((trialPlot: any, index: number) => {
    const ratesForPlotsAndReplicants: any = [];
    const { id, rows, columns } = trialPlot.plot;
    const dataAlreadyFilledSeeds = get(
      trialPlot,
      "properties.seeds.rates_and_dosages",
      []
    );
    const dataAlreadyFilledNutrients = get(
      trialPlot,
      "properties.fertilisers.rates_and_dosages",
      []
    ).map((obj: any) => {
      return {
        fertiliser_rate: obj.rate,
        fertiliser_amount: obj.amount,
      };
    });

    const mergedData = dataAlreadyFilledSeeds.map(
      (seedData: any, index: number) => {
        const nutrientData = dataAlreadyFilledNutrients[index];
        return { ...seedData, ...nutrientData };
      }
    );

    const existingRD = existingRates?.length
      ? existingRates[index]
      : mergedData;

    if (existingRD?.length === rows * columns) {
      const allPlotIds: string[] = [];
      // Set the plot_id as it is not storing in DB
      for (let row = 0; row < rows; row++) {
        for (let col = 0; col < columns; col++) {
          const plotId = `${id as number}-${row}-${col}`;
          allPlotIds.push(plotId);
        }
      }
      ratesAndDosages?.push(
        existingRD?.map((e: any, i: number) => {
          return { ...e, plot_id: allPlotIds[i], collapseValue: activeKey };
        })
      );
    } else {
      for (let row = 0; row < rows; row++) {
        for (let col = 0; col < columns; col++) {
          const plotId = `${id as number}-${row}-${col}`;
          const existingData = existingRatesDosage[index]?.find(
            (item: any) => item.plot_id === plotId
          );
          ratesForPlotsAndReplicants.push({
            plot_id: plotId,
            rate: 0,
            variety: "",
            collapseValue: activeKey,
            fertiliser_rate: 0,
            fertiliser_amount: 0,
          });
        }
      }
      ratesAndDosages.push(ratesForPlotsAndReplicants);
    }
  });

  return {
    rates_and_dosages: ratesAndDosages,
    type: "seeds",
    unit_of_rate: unit ?? "seeds/ha", // TODO: Check if this is correct
    standard_seed_rate: standardSeedRate,
    standard_product: standardProduct,
    standard_fertilisers: standardfertilisers,
  };
};

export default populateRatesDosage;
