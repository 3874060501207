import React, { useEffect, useState } from "react";
import { Sidebar } from "syngenta-digital-cropwise-react-ui-kit";
import * as amplitude from "@amplitude/analytics-browser";
import "./sidebar.less";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppState } from "../../lib/appContext/AppContext";
import { route } from "../../pages/constant";
import { useAuth } from "../../lib/auth/AuthContext";
import { getUserAmplitudeData } from "../utils/getUserAmplitudeData";
interface RouteInterface {
  name: string;
  path: string;
}

export const SidebarMenu: React.FC = (props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { stateValue, setStateValue } = useAppState();
  const { user } = useAuth();
  const amplitudeUserData = getUserAmplitudeData(user, stateValue);

  const isAssessment = window.location.pathname.toString().includes("reports")
    ? "REPORTS"
    : "";

  const [active, setActive] = useState(isAssessment);

  const routes: Record<string, RouteInterface> = {

    ...(stateValue.orgValue === null && stateValue.farmValue === null
      ? {
        organizations: {
          name: "organizations",
          path: `/${route.ORGS.path}`,
        }
      } : {}),
    ...(stateValue.farmValue === null && stateValue.orgValue !== null 
      ? {
        farms: {
          name: "farms",
          path: `/${route.FARMS.path}`,
        },
      }
      : {}),
    ...(stateValue.orgValue !== null && stateValue.farmValue !== null
      ? {
        trials: {
          name: "trials",
          path: `/${route.TRIALS.path}`,
        },
        protocols: {
          name: "protocols",
          path: `/${route.PROTOCOLS_DASHBOARD.path}`,
        },
      }
      : {}),
  };
  const routesMap = Object.keys(routes);

  useEffect(() => {
    const routeKey = routesMap?.find(
      (route) => `/app${routes[route].path}` === pathname
    );
    setActive(routeKey ?? "");
  }, [pathname]);

  useEffect(() => {
    if (stateValue.orgValue === null) {
      navigate(`/app/${route.ORGS.path}`);
    } else if (stateValue.farmValue === null) {
      navigate(`/app/${route.FARMS.path}`, {
        state: { id: stateValue.orgValue.id },
      });
    }
  }, [stateValue.orgValue, stateValue.farmValue, navigate]);

  return (
    <Sidebar
      data-cy="sidebar"
      test-id="sidebar"
      theme="dark"
      selectedKeys={[active]}
      collapsedMenu={true}
      // @ts-expect-error suppressing below error
      menuItems={routesMap.map((route) => ({
        key: route,
        label: routes[route].name,
        icon: (
          <img
            className="sidebar-icon active"
            alt={route.toLowerCase()}
            src={require(`../../assets/images/${route
              .toLowerCase()
              .replaceAll("_", "-")}.svg`)}
          />
        ),
        onClick: async (e: any) => {
          amplitude.logEvent("Left Menu Click", { buttonName: routes[route]?.name, amplitudeUserData});
          if (route === "organizations") {
            setStateValue({
              orgsData: null,
              orgValue: null,
              farmValue: null,
              assigneeValue: null
            });
          } else if (route === "farms") {
            setStateValue({
              ...stateValue,
              farmValue: null,
              assigneeValue: null
            });
          } else {
            setActive(route);
            navigate(`/app${routes[route].path}`);
          }
        },
      }))}
    />
  );
};
