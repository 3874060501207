import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../../../shared/utils/translationUtils";
import PlotsComponent from "../../../../shared/layout/Plots";
import StandardFertilizerRate from "../../../../shared/layout/FertilizersRate";

const FertiliserComponent: FC = () => {
  const { t } = useTranslation();
  return (
    <div className="rates-main">
      <h3 className="title">
        {formatTranslation(t("newTrial.ratesDosage.Fertiliser"))}
      </h3>
      <StandardFertilizerRate />
      <PlotsComponent from={"nutrients"} />
    </div>
  );
};

export default FertiliserComponent;
