import { Button as AntButton, Flex, Checkbox, Menu, Dropdown } from "antd";
import { useEffect, useState, type ReactElement } from "react";
import * as amplitude from "@amplitude/analytics-browser";
import { useNavigate } from "react-router-dom";
import { PTable } from "../../shared/layout/PTable";
import { ProtocolsColumns } from "./table-config";
import { Button } from "syngenta-digital-cropwise-react-ui-kit";
import { PrimaryButton } from "../../shared/layout/PrimaryButton";
import "./style.less";
import { route } from "../constant";
import { useAppState } from "../../lib/appContext/AppContext";
import filterIcon from "../../assets/images/icon-filter.svg";
import { Search } from "../../shared/layout/Search";
import AxiosInstance from "../../shared/utils/axios";
import { useTranslation } from "react-i18next";
import type { Status } from "../../shared/layout/types";
import { formatTranslation } from "../../shared/utils/translationUtils";
import { PlusOutlined } from "@ant-design/icons";
import emptyStateImage from "../../assets/images/Illustration-small-empty.svg";
import { Loader } from "../../shared/components/Loader";
import ProtocolDrawer from "../../shared/layout/ProtocolDrawer";
import { useAuth } from "../../lib/auth/AuthContext";
import { getUserAmplitudeData } from "../../shared/utils/getUserAmplitudeData";

const Protocols = (): ReactElement => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState<string>("");
  const [protocolsData, setProtocolsData] = useState<any>([]);
  const [filteredProtocolsData, setFilteredProtocolsData] = useState<any>([]);
  const { stateValue } = useAppState();
  const [selectedStatuses, setSelectedStatuses] = useState<Status[]>([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [protocolId, setProtocolId] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [pendingSelectedStatuses, setPendingSelectedStatuses] = useState<
    Status[]
  >([]);

  const { user } = useAuth();
  const amplitudeUserData = getUserAmplitudeData(user, stateValue);

  const fetchData = async (): Promise<void> => {
    setIsLoading(true);
    try {
      const response = await AxiosInstance.get(
        `/${route.PROTOCOLS.path}?org_id=${
          stateValue.orgValue?.id as string
        }&limit=99`
      );
      setProtocolsData(response.data);
      setFilteredProtocolsData(response.data); // Initialize with full data
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const showDrawer = (pId: string): void => {
    setProtocolId(pId);
    setOpen(true);
  };

  const onClose = (): void => {
    setOpen(false);
  };

  useEffect(() => {
    const fetchDataAndHandleError = async (): Promise<void> => {
      try {
        await fetchData();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    void fetchDataAndHandleError();
  }, [stateValue.orgValue]);

  const normalizeStatus = (status: string): Status => {
    return (status.charAt(0).toUpperCase() +
      status.slice(1).toLowerCase()) as Status;
  };

  const handleCheckboxChange = (status: Status): void => {
    const normalstatus = normalizeStatus(status);
    setPendingSelectedStatuses((prev) =>
      prev.includes(normalstatus)
        ? prev.filter((s) => s !== normalstatus)
        : [...prev, normalstatus]
    );
  };
  // Status  letters are capital in type and  get status in lower case  from api

  useEffect(() => {
    const filteredData = protocolsData.filter((protocol: any) =>
      protocol.name.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredProtocolsData(filteredData);
  }, [searchText, protocolsData]);

  // Separate useEffect for selected statuses
  useEffect(() => {
    const filteredData = protocolsData.filter((protocol: any) => {
      const protocolStatus = normalizeStatus(protocol.status);
      return (
        selectedStatuses.length === 0 ||
        selectedStatuses.includes(protocolStatus)
      );
    });
    setFilteredProtocolsData(filteredData);
  }, [selectedStatuses, protocolsData]);

  const handleDeleteProtocol = async (protocolId: string): Promise<void> => {
    amplitude.logEvent(
      "protocol",
      { buttonName: "delete-protocol", amplitudeUserData}
    );
    try {
      await AxiosInstance.delete(`/protocol/${protocolId}`);
      await fetchData();
    } catch (error) {
      console.error("delete errror:", error);
    }
  };

  const resetFilter = (): void => {
    setPendingSelectedStatuses([]);
    setSelectedStatuses([]);
    setFilteredProtocolsData(protocolsData); // Reset to full data
    setDropdownVisible(false);
  };
  const applyFilter = (): void => {
    setSelectedStatuses(pendingSelectedStatuses);
    setDropdownVisible(false);
  };

  const items = [
    {
      key: "Draft",
      label: (
        <Checkbox
          checked={pendingSelectedStatuses.includes("Draft")}
          onChange={() => {
            handleCheckboxChange("Draft");
          }}
        >
          Draft
        </Checkbox>
      ),
    },
    {
      key: "Published",
      label: (
        <Checkbox
          checked={pendingSelectedStatuses.includes("Published")}
          onChange={() => {
            handleCheckboxChange("Published");
          }}
        >
          Published
        </Checkbox>
      ),
    },
    {
      key: "apply-reset",
      label: (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <AntButton type="link" onClick={resetFilter}>
            Reset
          </AntButton>
          <AntButton type="primary" onClick={applyFilter}>
            Apply
          </AntButton>
        </div>
      ),
      disabled: true,
    },
  ];

  const columns = ProtocolsColumns(handleDeleteProtocol);

  const navigate = useNavigate();
  const handleNavigate = (): void => {
    amplitude.logEvent(
      "protocol",
      { buttonName: "create-protocol", amplitudeUserData}
    );
    navigate(`/app/${route.PROTOCOLS.path}/${route.PROTOCOLS_TRIALTYPE.path}`);
  };
  const handeTableChange = (): void => {};

  return (
    <div className="protocols-dashboard-page">
      <div className="protocols-header">
        <h3 className="text-xl text-semibold">
          {formatTranslation(t("protocols.title"))}
        </h3>

        {protocolsData.length !== 0 && (
          <PrimaryButton onClick={handleNavigate}>
            <PlusOutlined />
            {formatTranslation(t("protocols.createButton"))}
          </PrimaryButton>
        )}
      </div>
      <div className="body-content-protocol">
        {isLoading && <Loader mask message="" />}
        {protocolsData.length !== 0 && (
          <Flex align="center" justify="space-between">
            <p className="text-lg">
              {formatTranslation(t("protocols.protocolList"))}
            </p>
            <div>
              <Flex className="protocols-search-container">
                <Search
                  className="text-semibold"
                  searchText={searchText}
                  setSearchTexts={setSearchText}
                />
                <Dropdown
                  overlay={<Menu items={items} />}
                  trigger={["click"]}
                  visible={dropdownVisible}
                  onVisibleChange={(flag) => {
                    setDropdownVisible(flag);
                  }}
                >
                  <Button
                    className="protocols-filter-btn"
                    onClick={() => {
                      setDropdownVisible(true);
                    }}
                  >
                    <img src={filterIcon} alt="Filter" />
                  </Button>
                </Dropdown>
              </Flex>
            </div>
          </Flex>
        )}
        {protocolsData.length > 0 ? (
          <PTable
            onRow={(record) => {
              return {
                onClick: () => {
                  amplitude.logEvent(
                    "protocol",
                    {
                      buttonName: "view-protocol",
                      amplitudeUserData 
                    }
                  );
                  record?.id && showDrawer(record?.id);
                },
              };
            }}
            onChange={handeTableChange}
            columns={columns}
            dataSource={
              filteredProtocolsData.length > 0
                ? filteredProtocolsData
                : protocolsData
            }
            pagination={{
              position: ["bottomLeft"],
              total: filteredProtocolsData.length,
              showSizeChanger: true,
              showTotal: (total, [starting, ending]) => (
                <div>
                  {" "}
                  items per page. Total <strong>{total}</strong> Items
                </div>
              ),
              pageSizeOptions: [5, 10, 15, 20, 25],
              locale: { items_per_page: "" },
            }}
          />
        ) : (
          <div className="protocol-empty-state-container">
            <div className="empty-state-content">
              <img
                src={emptyStateImage}
                alt="empty state image"
                className="empty-state-img"
              />
              <div className="empty-state-heading">
                <h2>{formatTranslation(t("protocols.heading"))}</h2>
                <p>{formatTranslation(t("protocols.emptyStateDes1"))}</p>
                <p>{formatTranslation(t("protocols.emptyStateDes2"))}</p>
              </div>
              <PrimaryButton onClick={handleNavigate}>
                <PlusOutlined />
                {formatTranslation(t("protocols.createButton"))}
              </PrimaryButton>
            </div>
          </div>
        )}

        {protocolId && (
          <ProtocolDrawer
            open={open}
            onClose={onClose}
            protocolId={protocolId}
          />
        )}
      </div>
    </div>
  );
};

export default Protocols;
