/* eslint-disable */
import type {
  AbLine,
  NewTrialStateInterface,
} from "../../pages/new-trail/types";
import type { PolygonGeoJSON } from "../components/MapComponent/type";
import mapboxgl from "mapbox-gl";
import * as turf from "@turf/turf";
import bLineIcon from "../../assets/images/b-line-img.svg";
import aLineIcon from "../../assets/images/a-line-img.svg";
import _ from "lodash";
import { drawMachineryGridPure } from "./abLine";

const FEATURES_INDEX_ZERO = 0;
const INDEX_ZERO = 0;
const INDEX_ONE = 1;
const WHITE_COLOR = "white";
let previousAbLine: AbLine[] | null = null;
const mapboxMarkers: Record<string, mapboxgl.Marker> = {};

export const addABLinesToMap = (
  map: mapboxgl.Map | null,
  newTrialState: NewTrialStateInterface,
  setNewTrialState: any,
  polygonGeoJSON: PolygonGeoJSON,
  createCustomMarkerElement: (icon: string) => HTMLElement,
  getAbLineColor: (id: number, type: string) => string,
  currentStep?: number
): void => {
  if (!map || !newTrialState.ab_line) return;

  const polygon = polygonGeoJSON.features[FEATURES_INDEX_ZERO].geometry;

  if (previousAbLine) {
    const previousIds = new Set(
      previousAbLine.map((abItem: AbLine) => abItem.id)
    );
    const currentIds = new Set(
      newTrialState.ab_line.map((abItem: AbLine) => abItem.id)
    );

    previousIds.forEach((id) => {
      if (!currentIds.has(id)) {
        removeLayerAndSources(map, id as number);
      }
    });
  }

  newTrialState.ab_line.forEach((abItem: AbLine) => {
    const sourceId = `ab-line-geojson-data-${abItem.id as number}`;
    const layerId = `ab-line-layer-${abItem.id as number}`;
    if (!map.getSource(sourceId) || !map.getLayer(layerId)) {
      createNewLineLayer(
        map,
        abItem,
        polygon,
        createCustomMarkerElement,
        sourceId,
        layerId,
        newTrialState,
        setNewTrialState,
        polygonGeoJSON,
        getAbLineColor
      );
    } else {
      updateLayerVisibilityAndSelection(
        map,
        layerId,
        abItem.visible,
        abItem.id as number,
        currentStep === 1,
        polygonGeoJSON
      );
    }
  });
  previousAbLine = newTrialState.ab_line.map((abItem) => ({ ...abItem }));
};

const createNewLineLayer = (
  map: mapboxgl.Map,
  abItem: AbLine,
  polygon: turf.Polygon,
  createCustomMarkerElement: (icon: string) => HTMLElement,
  sourceId: string,
  layerId: string,
  newTrialState: any,
  setNewTrialState: any,
  polygonGeoJSON: any,
  getAbLineColor: (id: number, type: string) => string
): void => {
  const [pointA, pointB] = getLinePoints(abItem, polygon);

  const marker1 = createMarker(
    map,
    pointA,
    aLineIcon,
    INDEX_ZERO,
    abItem.id as number,
    createCustomMarkerElement,
    abItem.selected
  );

  const marker2 = createMarker(
    map,
    pointB,
    bLineIcon,
    INDEX_ONE,
    abItem.id as number,
    createCustomMarkerElement,
    abItem.selected
  );

  const machinaryGridArray = getMachineryGridArray(newTrialState);

  const debouncedUpdateLineSourceOnDrag = _.debounce(() => {
    updateLineSourceOnDrag(
      marker1,
      marker2,
      map,
      sourceId,
      abItem,
      polygonGeoJSON,
      getAbLineColor
    );
  }, 500);

  marker1.on("drag", debouncedUpdateLineSourceOnDrag);
  marker2.on("drag", debouncedUpdateLineSourceOnDrag);

  const line = turf.lineString([pointA, pointB] as any);
  map.addSource(sourceId, { type: "geojson", data: line });
  map.addLayer({
    id: layerId,
    type: "line",
    source: sourceId,
    paint: {
      "line-color": getAbLineColor(abItem?.id as number, "hexCode"),
      "line-width": 4,
      "line-dasharray": [2, 2],
    },
  });

  updateLineSource(
    machinaryGridArray,
    marker1,
    marker2,
    map,
    abItem,
    polygonGeoJSON,
    getAbLineColor
  );
};

const getLinePoints = (
  abItem: AbLine,
  polygon: turf.Polygon
): [mapboxgl.LngLatLike, mapboxgl.LngLatLike] => {
  if (
    abItem.geojson.geometry.coordinates[INDEX_ZERO] === 0 &&
    abItem.geojson.geometry.coordinates[INDEX_ONE] === 0
  ) {
    const randomPoints = turf.randomPoint(2, { bbox: turf.bbox(polygon) });
    return [
      randomPoints.features[INDEX_ZERO].geometry
        .coordinates as mapboxgl.LngLatLike,
      randomPoints.features[INDEX_ONE].geometry
        .coordinates as mapboxgl.LngLatLike,
    ];
  } else {
    return [
      abItem.geojson.geometry.coordinates[INDEX_ZERO],
      abItem.geojson.geometry.coordinates[INDEX_ONE],
    ];
  }
};

const getMachineryGridArray = (newTrialState: any): any[] => {
  if (newTrialState.ab_line.length === 0) return [];
  return newTrialState.ab_line.map((abItem: any) => ({
    id: `grid-layer-${abItem.id as string}`,
    ABLine_id: abItem.id,
    name: "Machinery Grid",
    key: "Machinery Grid",
    grid_width: abItem.grid_width,
    visible: abItem.visible,
    rotation: 0,
  }));
};

const updateLineSourceOnDrag = (
  marker1: mapboxgl.Marker,
  marker2: mapboxgl.Marker,
  map: mapboxgl.Map,
  sourceId: string,
  abItem: AbLine,
  polygonGeoJSON: PolygonGeoJSON,
  getAbLineColor: (id: number, type: string) => string
): void => {
  const updatedPointA = marker1.getLngLat().toArray();
  const updatedPointB = marker2.getLngLat().toArray();
  const line = turf.lineString([updatedPointA, updatedPointB]);
  const source = map.getSource(sourceId) as mapboxgl.GeoJSONSource;
  source.setData(line);
  abItem.geojson.geometry.coordinates = [updatedPointA, updatedPointB] as any;
  const sourceIdGrid = `grid-layer-${abItem.id ?? ""}`;
  //updateABWidth(abItem, sourceIdGrid, map, polygonGeoJSON);
  const turfPolygon = turf.polygon(
    polygonGeoJSON.features[0].geometry.coordinates
  );

  const layerId = `grid-line-layer-${abItem.id ?? ""}`;
  drawMachineryGridPure(
    map,
    turfPolygon,
    updatedPointA,
    updatedPointB,
    sourceIdGrid,
    layerId,
    {
      spacing: abItem.grid_width,
      color: getAbLineColor(abItem.id as number, "hexCode"),
    }
  );
};

const updateLineSource = (
  machinaryGridArray: any[],
  marker1: mapboxgl.Marker,
  marker2: mapboxgl.Marker,
  map: mapboxgl.Map,
  abItem: AbLine,
  polygonGeoJSON: PolygonGeoJSON,
  getAbLineColor: (id: number, type: string) => string
): void => {
  const updatedPointA = marker1.getLngLat().toArray();
  const updatedPointB = marker2.getLngLat().toArray();
  const line = turf.lineString([updatedPointA, updatedPointB]);
  const source = map.getSource(
    `ab-line-geojson-data-${abItem.id}`
  ) as mapboxgl.GeoJSONSource;
  source.setData(line);
  abItem.geojson.geometry.coordinates = [updatedPointA, updatedPointB] as any;
  const angleDegrees = calculateAngleDegrees(updatedPointA, updatedPointB);

  const updatedMachineryGrid = machinaryGridArray.map((item: any) => {
    if (item.ABLine_id === abItem.id) {
      return { ...item, rotation: -angleDegrees };
    }
    return item;
  });
  const turfPolygon = turf.polygon(
    polygonGeoJSON.features[0].geometry.coordinates
  );
  const sourceId = `grid-layer-${abItem.id ?? ""}`;
  const layerId = `grid-line-layer-${abItem.id ?? ""}`;
  drawMachineryGridPure(
    map,
    turfPolygon,
    updatedPointA,
    updatedPointB,
    sourceId,
    layerId,
    {
      spacing: abItem.grid_width,
      color: getAbLineColor(abItem.id as number, "hexCode"),
    }
  );

  //drawLinesAndGrid(map,polygonGeoJSON.features[0],{spacing : newTrialState.machinery_grid?.[0]?.width},newTrialState.ab_line??[])
  //   addMachineryGridToMap(
  //     map,
  //     updatedMachineryGrid,
  //     polygonGeoJSON,
  //     getAbLineColor
  //   );
};

const calculateAngleDegrees = (pointA: number[], pointB: number[]): number => {
  const angleRadians = Math.atan2(pointB[1] - pointA[1], pointB[0] - pointA[0]);
  return (angleRadians * 180) / Math.PI;
};

const addMachineryGridToMap = (
  map: mapboxgl.Map | null,
  machinaryGridArray: any[],
  polygonGeoJSON: PolygonGeoJSON,
  getAbLineColor?: (id: number, type: string) => string
): void => {
  if (!map) return;

  machinaryGridArray.forEach((gridItem: any) => {
    const { rotation, grid_width, visible, ABLine_id } = gridItem;
    if (!grid_width) return;

    const bbox = turf.bbox(
      polygonGeoJSON.features[FEATURES_INDEX_ZERO].geometry
    );
    const expandedBbox = expandBbox(bbox);
    const grid = turf.squareGrid(
      expandedBbox as turf.helpers.BBox,
      grid_width,
      { units: "meters" }
    );
    const rotatedGrid = turf.transformRotate(grid, rotation);
    const gridWithinPolygon = getGridWithinPolygon(rotatedGrid, polygonGeoJSON);

    const sourceId = `grid-layer-${ABLine_id ?? ""}`;
    const layerId = `grid-line-layer-${ABLine_id ?? ""}`;

    if (!map.getSource(sourceId)) {
      map.addSource(sourceId, {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: gridWithinPolygon,
        },
      });

      const gridColor = getAbLineColor
        ? getAbLineColor(ABLine_id as number, "hexCode")
        : WHITE_COLOR;
      map.addLayer({
        id: layerId,
        type: "line",
        source: sourceId,
        paint: {
          "line-color": gridColor || WHITE_COLOR,
          "line-width": 2,
        },
      });
    } else {
      (map.getSource(sourceId) as mapboxgl.GeoJSONSource).setData({
        type: "FeatureCollection",
        features: gridWithinPolygon,
      });
      if (map.getLayer(layerId)) {
        map.setLayoutProperty(
          layerId,
          "visibility",
          visible ? "visible" : "none"
        );
      }
    }
  });
};

const expandBbox = (bbox: turf.helpers.BBox): turf.helpers.BBox => {
  const bboxDiagonal = Math.sqrt(
    Math.pow(bbox[2] - bbox[0], 2) + Math.pow(bbox[3] - bbox[1], 2)
  );
  const centerX = (bbox[0] + bbox[2]) / 2;
  const centerY = (bbox[1] + bbox[3]) / 2;
  return [
    centerX - bboxDiagonal / 2,
    centerY - bboxDiagonal / 2,
    centerX + bboxDiagonal / 2,
    centerY + bboxDiagonal / 2,
  ];
};

const getGridWithinPolygon = (
  rotatedGrid: turf.FeatureCollection<turf.Polygon>,
  polygonGeoJSON: PolygonGeoJSON
): Array<turf.Feature<turf.Polygon>> => {
  return rotatedGrid.features
    .map(
      (cell) =>
        turf.intersect(
          cell.geometry,
          polygonGeoJSON.features[FEATURES_INDEX_ZERO].geometry
        ) as turf.Feature<turf.Polygon> | null
    )
    .filter(Boolean) as Array<turf.Feature<turf.Polygon>>;
};

const createMarker = (
  map: mapboxgl.Map,
  point: mapboxgl.LngLatLike,
  icon: string,
  markerIndex: number,
  id: number,
  createCustomMarkerElement: (icon: string) => HTMLElement,
  isSelected: boolean
): mapboxgl.Marker => {
  const markerId = `marker${markerIndex + 1}-${id}`;
  const marker = new mapboxgl.Marker({
    draggable: isSelected,
    element: createCustomMarkerElement(icon),
  })
    .setLngLat(point)
    .addTo(map);

  mapboxMarkers[markerId] = marker;
  return marker;
};

const removeLayerAndSources = (map: mapboxgl.Map, id: number): void => {
  const layerId = `ab-line-layer-${id}`;
  const sourceId = `ab-line-geojson-data-${id}`;
  if (map.getLayer(layerId)) {
    map.removeLayer(layerId);
  }
  if (map.getSource(sourceId)) {
    map.removeSource(sourceId);
  }

  const marker1Id = `marker1-${id}`;
  const marker2Id = `marker2-${id}`;

  if (mapboxMarkers[marker1Id]) mapboxMarkers[marker1Id].remove();
  if (mapboxMarkers[marker2Id]) mapboxMarkers[marker2Id].remove();

  const temp = map.getSource(`grid-layer-${id}`);
  if (temp) {
    map.removeLayer(`grid-line-layer-${id}`);
    map.removeSource(`grid-layer-${id}`);
  }
};

const updateLayerVisibilityAndSelection = (
  map: mapboxgl.Map,
  layerId: string,
  visible: boolean | undefined,
  id: number,
  isSelected: boolean,
  polygonGeoJSON: any
): void => {
  map.setLayoutProperty(layerId, "visibility", visible ? "visible" : "none");

  const marker1Id = `marker1-${id}`;
  const marker2Id = `marker2-${id}`;
  if (mapboxMarkers[marker1Id]) {
    mapboxMarkers[marker1Id].getElement().style.display = visible
      ? "block"
      : "none";
    mapboxMarkers[marker1Id].setDraggable(isSelected);
  }
  if (mapboxMarkers[marker2Id]) {
    mapboxMarkers[marker2Id].getElement().style.display = visible
      ? "block"
      : "none";
    mapboxMarkers[marker2Id].setDraggable(isSelected);
  }

  const gridId = `grid-line-layer-${id}`;
  if (map.getLayer(gridId)) {
    map.setLayoutProperty(gridId, "visibility", visible ? "visible" : "none");
  }
};

const updateABWidth = (
  abItem: AbLine,
  sourceId: string,
  map: mapboxgl.Map,
  polygonGeoJSON: PolygonGeoJSON
): void => {
  if (abItem.grid_width === 0) return;

  const bbox = turf.bbox(polygonGeoJSON.features[FEATURES_INDEX_ZERO].geometry);
  const expandedBbox = expandBbox(bbox);
  const grid = turf.squareGrid(
    expandedBbox as turf.helpers.BBox,
    abItem.grid_width as number,
    { units: "meters" }
  );
  const gridRotation = calculateAngle(abItem);
  const rotatedGrid = turf.transformRotate(grid, gridRotation);
  const gridWithinPolygon = getGridWithinPolygon(rotatedGrid, polygonGeoJSON);

  (map.getSource(sourceId) as mapboxgl.GeoJSONSource).setData({
    type: "FeatureCollection",
    features: gridWithinPolygon,
  });
};

const calculateAngle = (abItem: AbLine): number => {
  const coordinates = abItem.geojson.geometry.coordinates;
  const bearing1 = turf.bearing(turf.point([0, 0]), turf.point([0, 0])); // Assume fixed line
  const bearing2 = turf.bearing(
    turf.point(coordinates[0]),
    turf.point(coordinates[1])
  ); // Actual line
  let angle = Math.abs(bearing2 - bearing1);
  return bearing2 < 0 ? -angle : angle;
};
