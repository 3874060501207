import {
  createContext,
  useContext,
  useEffect,
  useState,
  type ReactElement,
} from "react";
import * as amplitude from "@amplitude/analytics-browser";
import { Button, Dropdown, Form } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CurrentSteps from "../../shared/layout/Steps";
import {
  getCancelButtonLabel,
  getCurrentStepNumber,
  getItems,
  getNextButtonLabel,
  nextStep,
  prevStep,
} from "./utils";
import arrowBack from "../../assets/images/arrow-back.svg";
import downIcon from "../../assets/images/keyboard-down-18.svg";
import leftIcon from "../../assets/images/left-arr.svg";
import rightGrayIcon from "../../assets/images/right-arr-gray.svg";
import rightWhiteIcon from "../../assets/images/right-arr-white.svg";
import "../style.less";
import "./style.less";
import {
  useNavigate,
  useLocation,
  useSearchParams,
  createSearchParams,
  useParams,
} from "react-router-dom";
import { type ProtocolStepItemType } from "./types";

import { protocolsSteps, SUBMIT_PROTOCOL_LABEL } from "./constants";
import { route } from "../constant";
import AxiosInstance from "../../shared/utils/axios";
import { useAppState } from "../../lib/appContext/AppContext";
// import { type protocolType } from "../protocols/types";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../shared/utils/translationUtils";
import type {
  createProtocolFormType,
  createProtocolValidationDataType,
} from "./shared/interfaces/product-interfaces";
import { useAuth } from "../../lib/auth/AuthContext";
import { getUserAmplitudeData } from "../../shared/utils/getUserAmplitudeData";
import { Loader } from "../../shared/components/Loader";
import TrialTypesComponent from "./step-components/TrialTypesComponent";
import RulesComponent from "./step-components/RulesComponent";
import ProductsComponent from "./step-components/ProductsComponent";
import SummaryComponent from "./step-components/SummaryComponent";

export type nextStepOptions = "draft" | "published" | "configure-trial";

const CreateProtocolContext = createContext<any>(undefined);

export const useCreateProtocolForm = (): any => {
  const context = useContext(CreateProtocolContext);
  if (context === undefined) {
    throw new Error(
      "useCreateProtocolForm must be used within a CreateProtocolProvider"
    );
  }
  return context;
};

const CreateProtocol = (): ReactElement => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [form] = Form.useForm<createProtocolFormType>();
  const formValues = form.getFieldsValue(true);
  const { step } = useParams();

  const [formValidationData, setFormValidationData] =
    useState<createProtocolValidationDataType | null>(null);

  const [checkValue, setCheckValue] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const { stateValue } = useAppState();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const currentStepNumber = getCurrentStepNumber(currentPath);

  const amplitudeUserData = getUserAmplitudeData(user, stateValue);

  const items = getItems();
  const [searchParams, setSearchParams] = useSearchParams();

  const protocolId = searchParams.get("protocol_id");

  const values = Form.useWatch([], form);

  useEffect(() => {
    if (currentStepNumber === 1) {
      if (
        formValidationData?.protocolType &&
        formValidationData?.protocolType.length > 0
      ) {
        setCheckValue(true);
      } else {
        setCheckValue(false);
      }
    }
  }, [values, formValues]);

  useEffect(() => {
    if (currentStepNumber === 2) {
      form
        .validateFields({ validateOnly: true, dirty: true })
        .then(() => {
          if (formValues.objectives?.length > 0) setCheckValue(true);
          else {
            setCheckValue(false);
          }
        })
        .catch(() => {
          setCheckValue(false);
        });
    }
  }, [values, formValues, formValidationData]);

  useEffect(() => {
    if (currentStepNumber === 3) {
      // Seeds check
      const seedsChecks =
        formValidationData?.products &&
        formValidationData?.products.length > 0 &&
        formValidationData?.measure_unit;
      // Nutrients check
      const nutrientsChecks = formValidationData?.isNutrientDataValid;
      if (
        formValidationData?.protocolType?.includes("seed") &&
        !formValidationData?.protocolType?.includes("nutrient") &&
        seedsChecks
      ) {
        setCheckValue(true);
      } else if (
        !formValidationData?.protocolType?.includes("seed") &&
        formValidationData?.protocolType?.includes("nutrient") &&
        nutrientsChecks
      ) {
        setCheckValue(true);
      } else if (
        formValidationData?.protocolType?.includes("seed") &&
        formValidationData?.protocolType?.includes("nutrient") &&
        seedsChecks &&
        nutrientsChecks
      ) {
        setCheckValue(true);
      } else {
        setCheckValue(false);
      }
    }
  }, [form, formValues, formValidationData]);

  const handleNextStep = async (
    nextStepOption: nextStepOptions
  ): Promise<void> => {
    if (protocolsSteps[currentStepNumber]) {
      amplitude.logEvent("Protocol-Steps", {
        buttonName: protocolsSteps[currentStepNumber]?.title,
        amplitudeUserData,
      });
    }

    const formValues = form.getFieldsValue(true);
    const nextStepData: ProtocolStepItemType = nextStep(currentPath);

    setIsUpdating(true);
    try {
      if (currentStepNumber === 1) {
        handleStepOne(nextStepData);
      } else if (currentStepNumber === 2) {
        await handleStepTwo(formValues, nextStepData);
      } else if (currentStepNumber === 3 && protocolId !== null) {
        await handleStepThree(formValues, nextStepData);
      } else if (
        currentPath === items[items?.length - 1].path &&
        protocolId !== null
      ) {
        await handleFinalStep(nextStepOption);
      }
    } catch (error) {
      console.log("common.error", error);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleStepOne = (nextStepData: ProtocolStepItemType): void => {
    navigate(nextStepData.path);
    if (protocolId !== null) {
      navigate({
        pathname: nextStepData.path,
        search: createSearchParams({
          protocol_id: protocolId,
        }).toString(),
      });
    }
  };

  const handleStepTwo = async (
    formValues: any,
    nextStepData: ProtocolStepItemType
  ): Promise<void> => {
    const payload = {
      org_id: stateValue.orgValue?.id,
      name: formValues.protocolName,
      description: formValues.protocolDescription,
      crop: formValues.protocolCrop,
      crop_details: {
        country: formValues.protocolCountry,
        crop_id: formValues.protocolCropId,
      },
      protocol_type: formValues.protocolType,
      plot_minimum_size: getPlotSize(
        formValues.plotMinWidth,
        formValues.plotMinLength
      ),
      plot_recommended_size: getPlotSize(
        formValues.plotRecommendedWidth,
        formValues.plotRecommendedLength
      ),
      objectives: formValues.objectives,
    };

    if (protocolId === null) {
      await makeApiCall("/protocol", "post", payload, nextStepData);
    } else {
      await makeApiCall(
        `/protocol/${protocolId}`,
        "patch",
        payload,
        nextStepData
      );
    }
  };

  const handleStepThree = async (
    formValues: any,
    nextStepData: ProtocolStepItemType
  ): Promise<void> => {
    if (
      formValidationData?.protocolType?.includes("seed") &&
      !formValues?.seed_details?.density_range?.min
    ) {
      formValues.seed_details.density_range = null;
    }
    const payload = {
      product_details: {
        seeds: formValues?.seed_details,
        biologicals: formValues?.biologicals,
        nutrients: formValues?.nutrient_details,
      },
    };

    if (protocolId !== null) {
      await makeApiCall(
        `/protocol/${protocolId}`,
        "patch",
        payload,
        nextStepData
      );
    }
  };

  const handleFinalStep = async (
    nextStepOption: nextStepOptions
  ): Promise<void> => {
    const payload =
      nextStepOption === "draft"
        ? { status: "draft" }
        : { status: "published" };
    if (protocolId !== null) {
      await makeApiCall(
        `/protocol/${protocolId}`,
        "patch",
        payload,
        undefined,
        nextStepOption
      );
    }
  };

  const makeApiCall = async (
    url: string,
    method: "post" | "patch",
    payload: any,
    nextStepData?: ProtocolStepItemType,
    nextStepOption?: nextStepOptions
  ): Promise<void> => {
    try {
      const response = await AxiosInstance[method](url, payload);
      if (response?.status !== 200 && !response?.data?.id) {
        throw new Error(
          response?.data?.errors?.[0]?.message || "common.error.patch"
        );
      }
      if (nextStepData) {
        setSearchParams({ protocol_id: response?.data?.id ?? protocolId });
        navigate({
          pathname: nextStepData.path,
          search: createSearchParams({
            protocol_id: response?.data?.id ?? protocolId,
          }).toString(),
        });
      } else if (nextStepOption) {
        navigate({
          pathname: `/app/${
            nextStepOption === "configure-trial"
              ? route.NEWTRIAL.path
              : route.PROTOCOLS_DASHBOARD.path
          }`,
        });
      }
    } catch (error: any) {
      if (error?.response?.data?.errors?.length > 0) {
        // error?.response?.data?.errors?.forEach?.((obj: any) => {
        //   toast.error(obj.message);
        // });
        handleError("protocol.toastMessage.patch", error);
      } else {
        handleError(
          method === "patch" ? "common.error.patch" : "common.error.post",
          error
        );
      }
    }
  };

  const getPlotSize = (width: number, length: number): any => {
    return isNaN(width) || isNaN(length) || width === null || length === null
      ? {}
      : { width, length };
  };

  const handleError = (messageKey: string, error?: any): void => {
    toast.error(formatTranslation(t(messageKey)));
    if (error) {
      console.error(formatTranslation(t(messageKey)), error);
    } else {
      console.error(formatTranslation(t(messageKey)));
    }
  };

  const handlePrevStep = (): void => {
    const pervStepData: ProtocolStepItemType | any = prevStep(currentPath);
    if (pervStepData !== undefined)
      if (protocolId !== null) {
        // check first step
        navigate({
          pathname: pervStepData?.path,
          search: createSearchParams({
            protocol_id: protocolId,
          }).toString(),
        });
      } else {
        navigate(pervStepData.path);
      }
    else navigate(`/app/${route.PROTOCOLS_DASHBOARD.path}`);
  };

  return (
    <div className="createProtocol-page">
      {isUpdating && <Loader mask message="Saving" />}
      <div className="createProtocol-header">
        <div className="breadcrumbs" data-testid="breadcrumbs-div">
          <Button className="Left-button-icon" onClick={handlePrevStep}>
            <img src={arrowBack} alt="left-icon" />
          </Button>
          <p>
            {formValidationData?.isNewProtocol
              ? formatTranslation(t("createProtocol.title"))
              : formatTranslation(t("createProtocol.titleEdit"))}
          </p>
        </div>
        <div className="stepper-common">
          <CurrentSteps current={currentStepNumber - 1} items={items} />
        </div>
      </div>
      <div className="createProtocol-body">
        <Form
          data-testid="form-basic"
          name="basic"
          form={form}
          style={
            currentStepNumber === 3 || currentStepNumber === 4
              ? { width: "100%" }
              : {}
          }
          layout="vertical"
          initialValues={{
            protocolType: null,
            plotRecommendedWidth: null,
            plotRecommendedLength: null,
            plotMinWidth: null,
            plotMinLength: null,
          }}
        >
          <ToastContainer stacked />
          <div className="router-outlet">
            <CreateProtocolContext.Provider
              value={{
                form,
                formValues,
                formValidationData,
                setFormValidationData,
              }}
            >
              {step === route.PROTOCOLS_TRIALTYPE.path && (
                <TrialTypesComponent />
              )}
              {step === route.PROTOCOLS_RULES.path && <RulesComponent />}
              {step === route.PROTOCOLS_PRODUCTS.path && <ProductsComponent />}
              {step === route.PROTOCOLS_SUMMARY.path && <SummaryComponent />}
            </CreateProtocolContext.Provider>
          </div>
        </Form>
      </div>
      <div className="bottom-sec-btn">
        <Button
          size="large"
          className="cancel-btn"
          data-testid="cancel-btn"
          onClick={handlePrevStep}
        >
          {<img src={leftIcon} style={{ color: "black" }} alt="left-icon" />}
          {getCancelButtonLabel(currentStepNumber)}
        </Button>

        <div className="split-button">
          <Button
            size="large"
            onClick={() => {
              void (async () => {
                await handleNextStep("configure-trial");
              })();
            }}
            className={`next-btn ${
              currentStepNumber === 4 ? "protocol-summary-btn" : ""
            } ${checkValue ? "" : "active"}`}
            htmlType="submit"
            data-testid="next-btn"
            disabled={!checkValue}
          >
            {getNextButtonLabel(currentStepNumber)}
            {getNextButtonLabel(currentStepNumber) !==
              SUBMIT_PROTOCOL_LABEL && (
              <img
                src={checkValue ? rightWhiteIcon : rightGrayIcon}
                alt="left-icon"
              />
            )}
          </Button>

          {currentStepNumber === 4 && protocolId !== null ? (
            <Dropdown
              menu={{
                items: [
                  {
                    label: formatTranslation(
                      t("createProtocol.dropdownOption1")
                    ),
                    key: "draft",
                  },
                  {
                    label: formatTranslation(
                      t("createProtocol.dropdownOption2")
                    ),
                    key: "published",
                  },
                ],
                onClick: (e) => {
                  amplitude.logEvent("Protocol-Steps", {
                    buttonName: e.key,
                    amplitudeUserData,
                  });
                  void (async () => {
                    await handleNextStep(e.key as nextStepOptions);
                  })();
                },
              }}
            >
              <Button className="next-btn protocol-save-options " size="large">
                {
                  <img
                    src={downIcon}
                    className="protocol-save-options-icon"
                    alt="down-icon"
                  />
                }
              </Button>
            </Dropdown>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateProtocol;
